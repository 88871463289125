import {
  Button,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import icon1 from "../../../assets/upload.png";
import dayjs from "dayjs";
import server from "../../../api/server";
import { useUserStor } from "../../../store";

// const user = JSON.parse(localStorage.getItem("user"));
const Profile = () => {
  const [profile_pic, setProfile_pic] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [address, setAddress] = useState("");

  const { adminData, setAdminData } = useUserStor();
  const [user, setUser] = useState(adminData);

  const [feedBack, setFeedBack] = useState(false);

  const token = localStorage.getItem("admin-token");

  const today = dayjs();
  const maxDate = today.subtract(20, "year");

  //validations
  const [serverError, setServerError] = useState({});
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          {serverError[field][0]}
        </div>
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFeedBack(false);
  };

  const formdata = new FormData();

  const handleUpdate = async () => {
    profile_pic && formdata.append("profile_pic", profile_pic);
    formdata.append("identity_number", user.identity_number);
    formdata.append("identity_type_id", user.identity_type_id);
    formdata.append("email", user.email);
    formdata.append("long", user.long);
    formdata.append("lat", user.lat);
    formdata.append("phone", user.phone);
    formdata.append("name", user.name);
    user.legal_representer &&
      formdata.append("is_available", user.legal_representer?.is_available);
    user.landNumber && formdata.append("landNumber", user.landNumber);
    user.districtName && formdata.append("districtName", user.districtName);
    user.districtCode && formdata.append("districtCode", user.districtCode);
    user.cityName && formdata.append("cityName", user.cityName);
    // formdata.append("iban", user.iban);
    // formdata.append("account_owner_name", user.account_owner_name);
    setServerError({});
    if (!user.name || !user.email || !user.phone || !user.identity_number) {
      if (!user.name) {
        setServerError((pre) => ({ ...pre, name: ["مطلوب"] }));
      }
      if (!user.email) {
        setServerError((pre) => ({ ...pre, email: ["مطلوب"] }));
      }
      if (!user.phone) {
        setServerError((pre) => ({ ...pre, phone: ["مطلوب"] }));
      }
      if (!user.identity_number) {
        setServerError((pre) => ({ ...pre, identity_number: ["مطلوب"] }));
      }
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/admin-app/user/update",

        formdata,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      // setAdminData(data.data);
      setUser(data.data.user);
      setAdminData(data.data.user);
      localStorage.setItem("user", JSON.stringify(data.data));
      setFeedBack(true);
      setLoading(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, [4000]);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.status === 422) {
        setServerError(error.response.data.error);
      }
    }
  };

  const getAddrss = async (lo, la) => {
    const link = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${la}&lon=${lo}`;
    fetch(link)
      .then((res) => res.json())
      .then((data) => {
        setAddress(data.display_name);
      });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUser((prev) => ({
        ...prev,
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }));
      // console.log(lat);
      getAddrss(position.coords.longitude, position.coords.latitude);
    });
    // getUser();
    // console.log(user);
    console.log(user);
  }, []);

  const handlecheckNationalId = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 1 }));
  };

  const handleCheckSpecialResidency = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 2 }));
  };

  const handleCheckResidencyId = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 3 }));
  };
  const handleCheckIs_available = () => {
    setUser((prev) => ({
      ...prev,
      legal_representer: { ...prev.legal_representer, is_available: 1 },
    }));
  };
  const handleCheckIs_not_available = () => {
    setUser((prev) => ({
      ...prev,
      legal_representer: { ...prev.legal_representer, is_available: 0 },
    }));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div style={{ width: "100%" }}>
      <Snackbar open={feedBack} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم التعديل بنجاح</div>
        </Alert>
      </Snackbar>
      {LoadingPage ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <div
              className="box1 logIn"
              style={{ height: "auto", gap: "0.1vmax" }}
            >
              <div className="pro-box">
                <div className="text10 text-r mr0">الصورة الشخصية</div>
                <img
                  alt="profile"
                  src={`https://api.qistar.sa/storage/${user.profile_pic}`}
                  className="pro-img"
                />
              </div>
              <div style={{ width: "100%" }} />
              <div className="box12" style={{ height: "auto" }}>
                <IconButton component="label">
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => setProfile_pic(e.target.files[0])}
                  />
                  <div className="box13">
                    <img src={icon1} alt="icon" />
                  </div>
                </IconButton>
                {profile_pic ? (
                  <div className="text2 t201 bold">{profile_pic.name}</div>
                ) : (
                  <div className="text2 t201 bold">ارفاق صورة شخصية</div>
                )}
              </div>

              {serverErrorMessage("profile_pic")}
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className="logIn" style={{ width: "90%", marginTop: "1rem" }}>
              <div className="text1">المعلومات الشخصية</div>
              {/* <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div
                    className={
                      user.identity_type_id === 3 ? "check-box2" : "check-box1"
                    }
                  >
                    <div className="text5">هوية مقيم</div>
                    <Checkbox
                      style={{ transform: "scale(0.7)" }}
                      onClick={handleCheckResidencyId}
                      checked={user.identity_type_id === 3}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div
                    className={
                      user.identity_type_id === 2 ? "check-box2" : "check-box1"
                    }
                  >
                    <div className="text5">إقامه مميزة</div>
                    <Checkbox
                      style={{ transform: "scale(0.7)" }}
                      onClick={handleCheckSpecialResidency}
                      checked={user.identity_type_id === 2}
                    />
                  </div>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div
                    className={
                      user.identity_type_id === 1 ? "check-box2" : "check-box1"
                    }
                  >
                    <div className="text5">هوية وطنية</div>
                    <Checkbox
                      style={{
                        transform: "scale(0.7)",
                        width: "2rem",
                      }}
                      onClick={handlecheckNationalId}
                      checked={user.identity_type_id === 1}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {serverErrorMessage("identity_type_id")}
                </Grid>
              </Grid> */}
              <Grid container spacing={2} direction={"row-reverse"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">الإسم</div>
                  <TextField
                    error={serverError.name}
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.name}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("name")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم الجوال</div>
                  <TextField
                    error={serverError.phone}
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    defaultValue={user.phone}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("phone")}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">تاريخ الميلاد</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileDatePicker"]}>
                      <MobileDatePicker
                        maxDate={dayjs().subtract(20, "year")}
                        sx={{
                          backgroundColor: "#F1F4FF",
                          width: "100%",
                          // direction: "rtl",
                          color: "GrayText",
                          fontFamily: "font",
                          padding: 0,
                        }}
                        size="small"
                        // value={dayjs(user.date)}
                        defaultValue={
                          user.dob
                            ? dayjs(user.dob)
                            : dayjs().subtract(20, "year")
                        }
                        className="textInput1"
                        variant="outlined"
                        format="DD-MM-YYYY"
                        onChange={(newValue) =>
                          setUser((prev) => ({
                            ...prev,
                            dob: newValue,
                          }))
                        }
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error: serverError.dob,
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {serverErrorMessage("dob")}
                </Grid> */}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">البريد الإلكتروني</div>
                  <TextField
                    error={serverError.email}
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    defaultValue={user.email}
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("email")}
                </Grid>
                <Grid item xs={6}>
                  <div className="text2">رقم الهوية</div>
                  <TextField
                    error={serverError.identity_number}
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.identity_number}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        identity_number: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("identity_number")}
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} direction={"row-reverse"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم الايبان</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.iban}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        iban: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">اسم صاحب الحساب</div>
                  <TextField
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    defaultValue={user.account_owner_name}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        account_owner_name: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid> */}
              <Grid container spacing={2} direction={"row-reverse"}>
                <Grid item xs={12} sx={{ direction: "rtl" }}>
                  <div
                    className="text10 text-r mr0"
                    style={{
                      color: "black",
                    }}
                  >
                    العنوان الوطني
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">المدينة</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.cityName}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        cityName: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("cityName")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">الرمز المختصر</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.districtCode}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        districtCode: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("districtCode")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">الحي</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.districtName}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        districtName: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("districtName")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم المبنى</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.landNumber}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        landNumber: e.target.value,
                      }))
                    }
                  />
                  {serverErrorMessage("landNumber")}
                </Grid>
              </Grid>
              {user.user_type_id === 3 && (
                <>
                  <div
                    className="text10 text-r mr0"
                    style={{
                      color: "black",
                    }}
                  >
                    ممثل قانوني
                  </div>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    alignContent="stretch"
                    wrap="wrap"
                  >
                    <Grid item xs={6}>
                      <div
                        className={
                          user.legal_representer?.is_available === 0
                            ? "check-box2"
                            : "check-box1"
                        }
                      >
                        <div className="text5">غير متوفر</div>
                        <Checkbox
                          style={{ transform: "scale(0.7)" }}
                          onClick={handleCheckIs_not_available}
                          checked={user.legal_representer?.is_available === 0}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        className={
                          user.legal_representer?.is_available === 1
                            ? "check-box2"
                            : "check-box1"
                        }
                      >
                        <div className="text5">متوفر</div>
                        <Checkbox
                          style={{ transform: "scale(0.7)" }}
                          onClick={handleCheckIs_available}
                          checked={user.legal_representer?.is_available === 1}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              <LoadingButton
                loading={Loading}
                variant="contained"
                sx={{
                  backgroundColor: "#004A98",
                  width: "100%",
                }}
                onClick={handleUpdate}
              >
                <div className="text3"> تحديث المعلومات الشخصية</div>
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Profile;
