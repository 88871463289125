import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material";
import SideNavigator from "./SideNavigator";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SubContracts from "./SubContracts";
import server from "../../../api/server";
import InstallmentTable from "./InstallmentTable";
import DepositeTable from "./DepositeTable";

const ContractDetails = () => {
  const partref = useRef(null);
  const propertyref = useRef(null);
  const installmentRef = useRef(null);
  const depositRef = useRef(null);
  const token = localStorage.getItem("admin-token");
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [legal_representer, set_legal_representer] = useState({});
  const [targetSubContract, setTargetSubContract] = useState({});

  const contractData = [
    { value: 7, label: "المدفوعة", color: "#27AE60" },
    { value: 3, label: "بانتظار الدفع", color: "#FFC300" },
  ];

  const getData = async () => {
    try {
      const { data } = await server.get(`/admin-app/contracts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
      setLoading(false);
      const SubContract = data.data.sub_contracts.filter(
        (contract) => contract.type_id === 1
      );
      setTargetSubContract(SubContract[0]);
      set_legal_representer(data.data.legal_representer);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid item xs={12}>
            <div
              className="box10"
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <div className="box11" style={{ width: "100%" }}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  {`العقد #${id}`}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <SideNavigator
              propertyref={propertyref}
              partref={partref}
              installmentRef={installmentRef}
              depositRef={depositRef}
            />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Box sx={boxStyle2}>
              <SubContracts data={data.sub_contracts} />
              {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
              {/* <Grid
								container
								spacing={1}
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								alignContent="stretch"
								wrap="wrap"
							>
								<Grid item xs={12} md={4}>
									<div
										className="admin-box1"
										style={{
											alignItems: "flex-start",
											padding: "10px",
										}}
									>
										<div
											style={{
												display: "flex",
												width: "100%",
												marginRight: "10px",
												marginTop: 0,
											}}
										>
											<div
												className="text8"
												style={{ marginRight: 5 }}
											>
												الأقساط
											</div>
										</div>

										<Grid
											container
											direction={"row"}
											display={"flex"}
											sx={{ mr: 1, mt: 1 }}
										>
											<Grid
												item
												xs={5}
												sx={{
													justifyContent: "center",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<div
													style={{ display: "flex" }}
												>
													<div className="shape1"></div>
													<p className="text9">
														المدفوعة : 7
													</p>
												</div>
												<div
													style={{ display: "flex" }}
												>
													<div className="shape2"></div>
													<p className="text9">
														بانتظار الدفع : 3{" "}
													</p>
												</div>
											</Grid>
											<Grid
												item
												xs={7}
												display={"flex"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												<PieChartWithCenterLabel
													data={contractData}
												/>
											</Grid>
										</Grid>
									</div>
								</Grid>
								<Grid item xs={12} md={8}>
									<div
										className="text8"
										style={{
											width: "100%",
											padding: "5px",
											borderTop: "10px",
											marginRight: "0%",
										}}
									>
										معلومات التعاقد
									</div>
									<div
										className="box2 m0"
										style={{ width: "auto" }}
									>
										<Grid item xs={4}>
											<p className="text11">
												عرض المستأجر
											</p>
											<p className="text12">
												{"9000"} ريال سنويا
											</p>
											<p className="text25">
												يتم تقسيط المبلغ على 12 شهر
												بمعدل
												{Math.round(
													Number(9000) / 12
												)}{" "}
												ريال شهريا.
											</p>
										</Grid>
										<Grid item xs={4}>
											<p className="text11">
												الرسوم الإدارية
											</p>
											<p className="text12">
												{1000} ريال
											</p>
										</Grid>
										<Grid item xs={4}>
											<p className="text11">الجملة</p>
											<p className="text12">
												{"10000"} ريال سنويا
											</p>
										</Grid>
									</div>
								</Grid>
							</Grid> */}
            </Box>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }} ref={partref}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  معلومات الأطراف
                </div>
              </div>
              <div className="" style={{ width: "100%", marginTop: 20 }}>
                <div className="text23 center" style={{ marginRight: "0%" }}>
                  بيانات المالك
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">الاسم</div>
                    <div className="text8">{data.owner.name}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">{data.owner.phone}</div>
                  </Grid>
                  <Grid item sx={6} md={4}>
                    <div className="text3">رقم الهوية</div>
                    <div className="text8">
                      {data.owner.identity_number || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <div className="" style={{ width: "100%" }}>
                <div
                  className="text23 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 20,
                  }}
                >
                  بيانات المستأجر
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">الاسم</div>
                    <div className="text8">{data.renter.name}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">{data.renter.phone}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الهوية</div>
                    <div className="text8">
                      {data.renter.identity_number || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <div className="" style={{ width: "100%" }}>
                <div
                  className="text23 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 20,
                  }}
                >
                  بيانات ممثل قسطار
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={8}>
                    <div className="text3">الاسم</div>
                    <div className="text8">
                      {legal_representer?.name || "لايوجد"}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">
                      {legal_representer?.phone || "لايوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={8}>
                    <div className="text3">السجل المدني</div>
                    <div className="text8">
                      {legal_representer?.identity_number || "لا يوجد"}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">العنوان الوطني</div>
                    <div className="text8">
                      {legal_representer?.national_address || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <div className="" style={{ width: "100%" }}>
                <div
                  className="text23 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 20,
                  }}
                >
                  بيانات الوسيط
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">الاسم</div>
                    <div className="text8">{data.broker.name}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">{data.broker.phone}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الهوية</div>
                    <div className="text8">
                      {data.broker.identity_number || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الايبان</div>
                    <div className="text8">{data.broker.iban || "لايوجد"}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">اسم صاحب الحساب</div>
                    <div className="text8">
                      {data.broker.account_owner_name || "لايوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div
                  ref={propertyref}
                  className="text8 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 10,
                  }}
                >
                  بيانات العقار
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">استخدام العقار</div>
                    <div className="text8">
                      {targetSubContract.building.building_use.name}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">نوع العقار</div>
                    <div className="text8">
                      {targetSubContract.building.building_type.name}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">نوع الوثيقة</div>
                    <div className="text8">
                      {targetSubContract.document_type?.name || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الوثيقة</div>
                    <div className="text8">{targetSubContract.ejar_no}</div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">مدة العقد</div>
                    <div className="text8">
                      {targetSubContract.contract_period} شهر \ أشهر
                    </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <div className="text3">رقم الايبان</div>
                    <div className="text8">
                      {targetSubContract.iban_no || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <div className="" style={{ width: "100%" }}>
                <div
                  className="text23 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 10,
                  }}
                >
                  العنوان الوطني
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={3}>
                    <div className="text3">المدينة</div>
                    <div className="text8">
                      {targetSubContract.building.cityName}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="text3">الرمز البريدي</div>
                    <div className="text8">
                      {targetSubContract.building.districtCode}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="text3">الحي</div>
                    <div className="text8">
                      {targetSubContract.building.districtName}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="text3">رقم المبنى</div>
                    <div className="text8">
                      {targetSubContract.building.landNumber}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div
                  ref={installmentRef}
                  className="text8 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 10,
                  }}
                >
                  الأقساط
                </div>
              </div>
              {data.installments ? (
                <InstallmentTable data={data.installments} />
              ) : (
                <div>لاتوجد سجلات</div>
              )}
            </Box>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div
                  ref={depositRef}
                  className="text8 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 10,
                  }}
                >
                  الإيرادات للمالك
                </div>
              </div>
              {data.deposits ? (
                <DepositeTable data={data.deposits} />
              ) : (
                <div>لاتوجد سجلات</div>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ContractDetails;

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};
