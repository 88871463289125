import {
  Button,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import icon1 from "../../../assets/upload.png";
// import "./profile.css";
import dayjs from "dayjs";
import server from "../../../api/server";
import Selector from "../../utils/Selector";
import { useNavigate } from "react-router-dom";

// const user = JSON.parse(localStorage.getItem("user"));
const AddUser = () => {
  const [profile_pic, setProfile_pic] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [user_type, setUser_type] = useState(1);

  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const [feedBack, setFeedBack] = useState(false);

  const token = localStorage.getItem("admin-token");

  //validations
  const [serverError, setServerError] = useState({});
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          {serverError[field][0]}
        </div>
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFeedBack(false);
  };

  const formdata = new FormData();

  const handleUpdate = async () => {
    profile_pic && formdata.append("profile_pic", profile_pic);
    formdata.append("identity_number", user.identity_number);
    formdata.append("identity_type_id", user.identity_type_id);
    formdata.append("email", user.email);
    formdata.append("phone", user.phone);
    formdata.append("name", user.name);
    formdata.append("user_type", user_type);
    formdata.append("is_active", 1);
    formdata.append("is_available", 0);
    user.landNumber && formdata.append("landNumber", user.landNumber);
    user.districtName && formdata.append("districtName", user.districtName);
    user.districtCode && formdata.append("districtCode", user.districtCode);
    user.cityName && formdata.append("cityName", user.cityName);
    setServerError({});
    if (!user.name || !user.email || !user.phone || !user.identity_number) {
      if (!user.name) {
        setServerError((pre) => ({ ...pre, name: ["مطلوب"] }));
      }
      if (!user.email) {
        setServerError((pre) => ({ ...pre, email: ["مطلوب"] }));
      }
      if (!user.phone) {
        setServerError((pre) => ({ ...pre, phone: ["مطلوب"] }));
      }
      if (!user.identity_number) {
        setServerError((pre) => ({ ...pre, identity_number: ["مطلوب"] }));
      }
      // if (!user.identity_type_id) {
      //   setServerError((pre) => ({
      //     ...pre,
      //     identity_type_id: ["نوع الهوية مطلوب"],
      //   }));
      // }
      return;
    }
    setLoading(true);
    try {
      const { data } = await server.post(
        "/admin-app/add-user",

        formdata,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      // setUserData(data.data);
      // setUser(data.data);
      // setUserData(data.data);
      navigate("/admin/users");
      setFeedBack(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setServerError(error.response.data.error);
    }
  };

  useEffect(() => {}, []);

  const handlecheckNationalId = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 1 }));
  };

  const handleCheckSpecialResidency = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 2 }));
  };

  const handleCheckResidencyId = () => {
    setUser((prev) => ({ ...prev, identity_type_id: 3 }));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div style={{ width: "100%" }}>
      <Snackbar open={feedBack} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم إنشاء الحساب</div>
        </Alert>
      </Snackbar>
      <Grid
        container
        direction={"row-reverse"}
        spacing={2}
        sx={{ mb: 4, direction: "rtl" }}
      >
        <Grid item xs={12}>
          <div
            className="logIn"
            style={{ width: "90%", marginTop: "1rem", direction: "rtl" }}
          >
            <div className="text1" style={{ width: "100%" }}>
              إنشاء حساب مستخدم
            </div>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">الإسم</div>
                <TextField
                  error={serverError.name}
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("name")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">رقم الجوال</div>
                <TextField
                  error={serverError.phone}
                  sx={{
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    fontFamily: "font",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("phone")}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="text2">رقم الهوية</div>
                <TextField
                  error={serverError.identity_number}
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      identity_number: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("identity_number")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">البريد الإلكتروني</div>
                <TextField
                  error={serverError.email}
                  sx={{
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    fontFamily: "font",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("email")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">نوع المستخدم</div>
                <Selector
                  value={user_type}
                  setValue={setUser_type}
                  api="get-user-types"
                />
                {serverErrorMessage("user_type")}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ direction: "rtl" }}>
                <div
                  className="text10 text-r mr0"
                  style={{
                    color: "black",
                  }}
                >
                  العنوان الوطني
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">المدينة</div>
                <TextField
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      cityName: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("cityName")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">الرمز المختصر</div>
                <TextField
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      districtCode: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("districtCode")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">الحي</div>
                <TextField
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      districtName: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("districtName")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="text2">رقم المبنى</div>
                <TextField
                  sx={{
                    fontFamily: "font",
                    backgroundColor: "#F1F4FF",
                    width: "100%",
                    direction: "rtl",
                    color: "GrayText",
                    // fontWeight: "700",
                    // height: "1rem",
                  }}
                  size="small"
                  className="textInput1"
                  variant="outlined"
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      landNumber: e.target.value,
                    }))
                  }
                />
                {serverErrorMessage("landNumber")}
              </Grid>
            </Grid>
            <LoadingButton
              loading={Loading}
              variant="contained"
              sx={{
                backgroundColor: "#004A98",
                width: "100%",
              }}
              onClick={handleUpdate}
            >
              <div className="text3">إنشاء الحساب</div>
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUser;
