import { useEffect, useState } from "react";
import server from "../../../api/server";
import CustomPaginationActionsTable from "./DataGrid";
import { useNavStore } from "../../../store";
import { Box, TextField, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Brokers = () => {
  const token = localStorage.getItem("admin-token");
  const [data, setData] = useState([]);
  const [selectValue, setSelectValue] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const getCustomers = async () => {
    try {
      const { data } = await server.get("/admin-app/brokers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const filterCustomers = async () => {
    try {
      const { data } = await server.get(
        `/admin-app/brokers?&name=${name}&phone=${phone}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "إدارة المستخدمين",
      },
    });
    getCustomers();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center" style={{ marginRight: "0%" }}>
            الوسطاء العقاريون
          </div>
        </div>
      </div>
      <div
        className="box14"
        style={{
          margin: "2rem",
          height: "auto",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", direction: "rtl", marginBlock: 2 }}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                name="name"
                placeholder="اسم العميل"
                autoComplete="off"
                inputProps={{ autoComplete: "new-password" }}
                sx={{
                  fontFamily: "font-light",
                  ".MuiInputBase-input": {
                    fontFamily: "font-light !important",
                    textAlign: "right",
                  },
                }}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                name="phone"
                type="number"
                placeholder="رقم الجوال"
                autoComplete="off"
                inputProps={{ autoComplete: "new-password" }}
                sx={{
                  fontFamily: "font-light",
                  ".MuiInputBase-input": {
                    fontFamily: "font-light !important",
                    textAlign: "right",
                  },
                }}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <LoadingButton
                variant="contained"
                size="large"
                sx={{
                  marginLeft: "10px",
                  width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={filterCustomers}
              >
                <div className="text3">فلترة</div>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            backgroundColor: "#F4F7FF",
            padding: 10,
            borderRadius: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          <CustomPaginationActionsTable rows={data} noPagination />
        </div>
      </div>
    </div>
  );
};

export default Brokers;
