import { useEffect, useState } from "react";
import server from "../../../../api/server";
import { Box, CircularProgress } from "@mui/material";
import CustomPaginationActionsTable from "./DataGrid";
import "./payment.css";

const PaymentRequests = () => {
  const token = localStorage.getItem("admin-token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const { data } = await server.get(`/admin-app/payment-requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box11" style={{ width: "100%" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                طلبات الصرف
              </div>
            </div>
          </div>
          <Box
            sx={{
              direction: "rtl",
              border: "1px solid var(--form-stroke, #E7E7E7)",
              padding: "15px",
              borderRadius: "8px",
              marginTop: 2,
              width: "auto",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <div className="text8">طلب صرف</div>
            </div>
            <div
              style={{
                backgroundColor: "#F4F7FF",
                padding: 10,
                borderRadius: 8,
                width: "auto",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {data.length !== 0 ? (
                <CustomPaginationActionsTable
                  rows={data}
                  noPagination
                  reload={getData}
                />
              ) : (
                <div
                  className="text8"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  لاتوجد طلبات صرف
                </div>
              )}
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default PaymentRequests;
