import dayjs from "dayjs";
export const showDate = (dateObj) => {
	// const date = new Date(dateObj);
	const date = dayjs(dateObj).format("DD-MM-YYYY").toString();
	// const day = date.date();
	// const month = date.month() + 1;
	// const year = date.year();
	// return `${day}-${month}-${year}`;
	return date;
};
