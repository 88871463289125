import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import server from "../../../api/server";
import { showDate } from "../../utils/common";
import { useParams } from "react-router-dom";

const MemorandumDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("admin-token");
  const { id } = useParams();
  const getData = async () => {
    try {
      const { data } = await server.get(
        `/admin-app/memorandum-understanding/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const openFile = () => {
    window.open(`https://api.qistar.sa/storage/${data?.file}`);
  };
  const renderType = (type) => {
    switch (type) {
      case "renter":
        return "مستأجر";
      case "owner":
        return "مالك";
      case "broker":
        return "وسيط";
      case "platform":
        return "منصة عقارية";
      default:
        return "لا يوجد";
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center">تفاصيل المذكرة</div>
        </div>
      </div>
      <Box
        sx={{
          width: "auto",
          bgcolor: "white",
          direction: "rtl",
          p: 2,
          mt: 3,
          borderRadius: "8px",
        }}
      >
        <div className="text8" style={{ marginBottom: 10 }}>
          المذكرة رقم {data.serial_no}
        </div>
        {loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <Grid container direction={"row"} sx={{ marginTop: "10px", mb: 2 }}>
            <Grid item xs={6}>
              <div className="text23 mr0">اسم الشركة / المؤسسة</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.name || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">المدينة</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.city || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">النوع</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {renderType(data?.type) || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">البريد الإلكتروني</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.email || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">الممثل</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.company_representer || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">صفة الممثل</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.position_company_representer || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">تاريخ الإنشاء</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {showDate(data?.created_at) || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">رقم الجوال</div>
              <div
                className="box15"
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <div className="text12 mr0 f-normal">
                  {data?.phone || "غير موجود"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text23 mr0">ملف المذكرة</div>
              <div
                className=""
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "font-normal",
                    bgcolor: "#014E97",
                    paddingInline: 5,
                  }}
                  onClick={openFile}
                >
                  تحميل
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default MemorandumDetails;
