import { RiskSelector } from "./RiskSelector";
import { ArrowCircleUpTwoTone, UploadFile } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import PieChartWithCenterLabel from "./PieChart";
import icone1 from "../../../assets/upload.png";
import { LoadingButton } from "@mui/lab";
import server from "../../../api/server";
import SendOwnerOffer from "./ConfirmModal";
import { useNavStore } from "../../../store";
import Offers from "./Offers";
import HorizontalLinearStepper from "./Stepper";
import { showDate } from "../../utils/common";
import StepperContract from "./StepperContract";

const AdminReqShow = () => {
  const req = JSON.parse(localStorage.getItem("req"));
  const [expanded, setExpanded] = useState(false);
  const [accepted, setAccepted] = useState(true);
  const [accept, setAccept] = useState(true);
  const [risk, setRisk] = useState("");
  const [file, setFile] = useState(null);
  const [note, setNote] = useState("");
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [openSendOffer, setOpenSendOffer] = useState(false);
  const [creditStatus, setCreditStatus] = useState(
    Number(req.credit_status_id) || 1
  );

  const [serverError, setServerError] = useState({});

  const formData = new FormData();
  formData.append("application_id", req.id);
  formData.append("status_id", 3);
  formData.append("note", note);
  formData.append("credit_file", file);
  formData.append("credit_risk_id", risk);

  const data = [
    { value: req.application_status_id, label: "", color: "#014E97" },
    { value: 10 - req.application_status_id, label: "", color: "#F1F4FF" },
  ];

  const date = showDate(req.created_at);
  const dob = showDate(req.renter?.dob) || "لايوجد";
  const token = localStorage.getItem("admin-token");

  const openFile = () => {
    // fetch(`https://api.qistar.sa/storage/${req.files.file_path}`).then(
    // 	(response) => {
    // 		response.blob().then((blob) => {
    // 			// Creating new object of PDF file
    // 			const fileURL = window.URL.createObjectURL(
    // 				new Blob([blob])
    // 			);

    // 			// Setting various property values
    // 			let alink = document.createElement("a");
    // 			alink.href = fileURL;
    // 			alink.setAttribute("download", req.files.file_name);
    // 			document.body.appendChild(fileURL);
    // 			alink.click();
    // 			alink.parentNode.removeChild(alink);
    // 		});
    // 	}
    // );
    if (!req.files) return;
    window.open(`https://api.qistar.sa/storage/${req.files[0]?.file_path}`);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const ServerErrorText = ({ value }) => {
    if (serverError[value]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[value]}
        </div>
      );
    }
  };

  const handleCheck = async () => {
    setLoadingCheck(true);
    try {
      const { data } = await server.post(
        "/admin-app/update-credit-statuses",
        {
          application_id: req.id,
          status_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify(data.data));
      setExpanded(true);
      setLoadingCheck(false);
      setCreditStatus(2);
    } catch (error) {
      setLoadingCheck(false);
      console.log(error);
    }
  };
  const handleAccept = async () => {
    if (!risk) {
      setServerError({ credit_risk_id: "اختر مستوى المخاطرة" });
      return;
    }
    setLoadingAccept(true);
    try {
      const { data } = await server.post(
        "/admin-app/update-credit-statuses",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify(data.data));
      setCreditStatus(3);
      setExpanded(false);
      setAccept(false);
      setLoadingAccept(false);
    } catch (error) {
      setLoadingAccept(false);
      console.log(error);
      setServerError(error.response.data.error);
    }
  };
  const handleReject = async () => {
    setLoadingReject(true);
    try {
      const { data } = await server.post(
        "/admin-app/update-credit-statuses",
        {
          application_id: req.id,
          status_id: 5,
          note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify(data.data));
      setCreditStatus(5);
      setLoadingReject(false);
      setExpanded(false);
      setAccept(false);
    } catch (error) {
      setLoadingReject(false);
      console.log(error);
    }
  };
  const handleEditable = async () => {
    setLoadingReject(true);
    try {
      const { data } = await server.post(
        "/admin-app/update-credit-statuses",
        {
          application_id: req.id,
          status_id: 4,
          note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify(data.data));
      setCreditStatus(4);
      console.log(data.data);
      setLoadingReject(false);
      setExpanded(false);
      setAccept(false);
    } catch (error) {
      setLoadingReject(false);
      console.log(error);
    }
  };

  const renderCreditButton = () => {
    if (creditStatus < 3 && req.application_status_id < 3) {
      return (
        <LoadingButton
          loading={loadingCheck}
          variant="contained"
          sx={{
            backgroundColor: "#004A98",
            width: "100%",
            marginBlock: "10px",
            display: expanded ? "none" : "flex",
          }}
          onClick={handleCheck}
        >
          <div className="text3"> إجراء الفحص الأئتماني</div>
        </LoadingButton>
      );
    } else if (creditStatus === 3) {
      return (
        <LoadingButton
          variant="outlined"
          sx={{
            // backgroundColor: "#004A98",
            width: "100%",
            marginBlock: "10px",
          }}
          disabled
        >
          <div className="text3 text-green">
            {" "}
            تمت الموافقة على القوائم المالية
          </div>
        </LoadingButton>
      );
    }
    if (creditStatus === 5) {
      return (
        <LoadingButton
          loading={loadingCheck}
          variant="outlined"
          sx={{
            width: "100%",
            marginBlock: "10px",
            display: expanded ? "none" : "flex",
          }}
        >
          <div className="text3" style={{ color: "#FF1F00" }}>
            {" "}
            تم رفض القوائم المالية
          </div>
        </LoadingButton>
      );
    }
    if (creditStatus === 4) {
      return (
        <LoadingButton
          loading={loadingCheck}
          variant="contained"
          sx={{
            backgroundColor: "#004A98",
            width: "100%",
            marginBlock: "10px",
            display: expanded ? "none" : "flex",
          }}
          onClick={handleCheck}
        >
          <div className="text3"> إجراء الفحص الأئتماني</div>
        </LoadingButton>
      );
    } else {
      return (
        <LoadingButton
          variant="outlined"
          sx={{
            // backgroundColor: "#004A98",
            width: "100%",
            marginBlock: "10px",
          }}
          disabled
        >
          <div className="text3 text-green">
            {" "}
            تمت الموافقة على القوائم المالية
          </div>
        </LoadingButton>
      );
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: `الطلب #${req.serial_number}`,
        items: [{ text: "إدارة الطلبات", path: "/admin/requests" }],
      },
    });
    console.log(creditStatus);
    if (creditStatus === 1) {
      setAccepted(false);
    }
  }, []);

  return (
    <div className="req-root">
      <SendOwnerOffer
        open={openSendOffer}
        setOpen={setOpenSendOffer}
        appId={req.id}
        token={token}
      />
      <div className="box10" style={{ marginTop: 20 }}>
        <div className="box11">
          <div className="text22 text-r center">{`الطلب #${req.serial_number}`}</div>
        </div>
        {req.application_status_id === 2 && creditStatus === 3 && (
          <div>
            <LoadingButton
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
              }}
              onClick={() => setOpenSendOffer(true)}
            >
              <div className="text3">إرسال عرض</div>
            </LoadingButton>
          </div>
        )}
      </div>

      <div className="box14" style={{ height: "auto", textAlign: "right" }}>
        <Grid
          container
          sx={{
            direction: "rtl",
            borderBottom: "1px solid var(--form-stroke, #E7E7E7)",
          }}
        >
          <Grid item xs={4}>
            <div className="box11">
              <div className="text22 center">{`الطلب #${req.serial_number}`}</div>
              <div className="text9" style={{ marginTop: "5%" }}>
                {date}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="box11">
              <div className="text9 center">حالة الطلب</div>
              <div
                className="text9"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="shape2"></div>
                <p className="text22 text-r" style={{ marginTop: "5%" }}>
                  {req.status.name}
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xs={11}></Grid>

            <div className="text9 center">مكتمل بنسبة</div>

            <PieChartWithCenterLabel data={data} />
          </Grid>
        </Grid>
        {/* <div className="box14" /> */}
        <Offers req={req} />
        <div
          style={{
            direction: "rtl",
            border: "1px solid var(--form-stroke, #E7E7E7)",
            padding: "10px",
            borderRadius: "8px",
            marginTop: 10,
            width: "100%",
          }}
        >
          {req.application_status_id === 7 ? (
            <HorizontalLinearStepper acitve={req.last_status_id - 1} error />
          ) : (
            <HorizontalLinearStepper
              acitve={
                req.application_status_id === 6
                  ? 6
                  : req.application_status_id - 1
              }
            />
          )}
        </div>
        {req.contracts && (
          <>
            <Grid
              container
              sx={{
                direction: "rtl",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                padding: "10px",
                borderRadius: "8px",
                mt: 2,
                // display: "inline-block",
              }}
            >
              <div className="text12 mr0 f-normal">
                حالة التعاقد عند الوسيط :
              </div>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <StepperContract
                  acitve={req.contracts.status_id - 1}
                  error={false}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div className="box10">
        <Grid
          container
          // direction={"row-reverse"}
          justifyContent={"space-between"}
          // spacing={1}
          sx={{ direction: "rtl", mt: 2, mb: 3 }}
        >
          <Grid item xs={7}>
            <>
              <div className="admin-box1" style={{ padding: 10 }}>
                <div className="text8" style={{ marginTop: "10px" }}>
                  بيانات العقار
                </div>
                <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <div className="text23 mr0">اسم المالك</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.property_owner_name || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0">المدينة</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.location || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0">الرمز البريدي</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.short_code || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0">الحي</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.property_neighbourhood || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0">رقم المبنى</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.property_no || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
            <>
              <div
                className="admin-box1"
                style={{ padding: 10, marginTop: 20 }}
                // style={{ width: "35vw", marginBottom: 30 }}
              >
                <div className="text8" style={{ marginTop: "10px" }}>
                  بيانات المستأجر
                </div>
                <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <div className="text23 mr0">الاسم الكامل</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.renter?.name || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0">تاريخ الميلاد</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">{dob}</div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
										<div className="text23 mr0">
											نوع الهوية
										</div>
										<div
											className="box15"
											style={{
												marginTop: 5,
												marginBottom: 15,
												fontFamily: "font-normal",
											}}
										>
											<div className="text12 mr0 f-normal">
												هوية وطنية
											</div>
										</div>
									</Grid> */}
                  <Grid item xs={12}>
                    <div className="text23 mr0"> البريد الإلكتروني</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.renter?.email || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0"> رقم الجوال</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.renter?.phone || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text23 mr0"> عدد الفروع</div>
                    <div
                      className="box15"
                      style={{
                        marginTop: 5,
                        marginBottom: 15,
                        fontFamily: "font-normal",
                      }}
                    >
                      <div className="text12 mr0 f-normal">
                        {req.branch_number || "غير موجود"}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          </Grid>
          <Grid item xs={4.5} sx={{ marginLeft: "0px" }}>
            <div className="admin-box1" style={{ padding: 10, width: "auto" }}>
              <div className="text8">الفحص الإئتماني</div>
              <div
                className="box2 mr0"
                style={{ marginTop: "10px", width: "auto" }}
              >
                <Grid item xs={6}>
                  <p className="text11">كشف حساب 3 أشهر</p>
                  <p className="text12 f-normal">
                    {req.files?.file_name || "لايوجد"}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="outlined"
                    disabled={creditStatus === 1}
                    onClick={openFile}
                  >
                    <div className="text13">تحميل</div>
                  </Button>
                </Grid>
              </div>
              {renderCreditButton()}
              <Grow
                in={expanded}
                style={{
                  display: !expanded ? "none" : "flex",
                  marginTop: 10,
                }}
              >
                <Stack
                  direction={"row-reverce"}
                  spacing={"10px"}
                  sx={{ gap: "10px" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#004A98",
                      width: "100%",
                    }}
                    onClick={() => setAccept(1)}
                  >
                    <div className="text3">موافقة</div>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#FFC300",
                      width: "100%",
                    }}
                    onClick={() => setAccept(2)}
                  >
                    <div className="text3">قابل للتعديل</div>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#FF1F00",
                      width: "100%",
                    }}
                    onClick={() => setAccept(3)}
                  >
                    <div className="text3">رفض</div>
                  </Button>
                </Stack>
              </Grow>
            </div>
            <Grow
              in={accept === 1}
              style={{
                marginTop: 15,
                display: accept === 1 ? "flex" : "none",
              }}
            >
              <div
                className="admin-box1"
                style={{ padding: 10, width: "auto" }}
              >
                <FormControl fullWidth sx={{ direction: "rtl" }}>
                  <div className="text23 mr0" style={{ marginBottom: 5 }}>
                    مستوى المخاطرة
                  </div>
                  <RiskSelector risk={risk} setRisk={setRisk} />
                  <ServerErrorText value={"credit_risk_id"} />
                  <div
                    className="text23 mr0"
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                    }}
                  >
                    ملاحظات
                  </div>
                  <TextField
                    multiline
                    sx={{
                      ":focus-within": {
                        backgroundColor: "#F1F4FF",
                      },
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                    }}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <ServerErrorText value={"note"} />

                  <LoadingButton
                    variant="outlined"
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                    component="label"
                  >
                    <VisuallyHiddenInput
                      onChange={(e) => setFile(e.target.files[0])}
                      type="file"
                    />
                    <UploadFile />
                    <div className="text3">{file ? file.name : "رفع ملف"}</div>
                  </LoadingButton>

                  <ServerErrorText value={"credit_file"} />

                  <LoadingButton
                    loading={loadingAccept}
                    variant="contained"
                    sx={{ backgroundColor: "#004A98" }}
                    onClick={handleAccept}
                  >
                    <div className="text3">إرسال</div>
                  </LoadingButton>
                </FormControl>
              </div>
            </Grow>
            <Grow
              in={accept === 2}
              style={{
                marginTop: 15,
                display: accept === 2 ? "flex" : "none",
              }}
            >
              <div
                className="admin-box1"
                style={{ padding: 10, width: "auto" }}
              >
                <FormControl fullWidth sx={{ direction: "rtl" }}>
                  <div
                    className="text23 mr0"
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                    }}
                  >
                    ملاحظات
                  </div>
                  <TextField
                    multiline
                    sx={{
                      ":focus-within": {
                        backgroundColor: "#F1F4FF",
                      },
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                    }}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) => setNote(e.target.value)}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: "#004A98",
                    }}
                    onClick={handleEditable}
                  >
                    <div className="text3">إرسال</div>
                  </Button>
                </FormControl>
              </div>
            </Grow>
            <Grow
              in={accept === 3}
              style={{
                marginTop: 15,
                display: accept === 3 ? "flex" : "none",
              }}
            >
              <div
                className="admin-box1"
                style={{ padding: 10, width: "auto" }}
              >
                <FormControl fullWidth sx={{ direction: "rtl" }}>
                  <div
                    className="text23 mr0"
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                    }}
                  >
                    ملاحظات
                  </div>
                  <TextField
                    multiline
                    size="smale"
                    sx={{
                      ":focus-within": {
                        backgroundColor: "#F1F4FF",
                      },
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                    }}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) => setNote(e.target.value)}
                  />

                  <LoadingButton
                    loading={loadingReject}
                    variant="contained"
                    onClick={handleReject}
                    sx={{
                      mt: 2,
                      backgroundColor: "#004A98",
                    }}
                  >
                    <div className="text3">إرسال</div>
                  </LoadingButton>
                </FormControl>
              </div>
            </Grow>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AdminReqShow;
