import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { DialogActions, DialogTitle, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRiskStore } from "../../../store";
import server from "../../../api/server";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddPopup({ open, setOpen }) {
  const [loadingSend, setLoadingSend] = useState(false);
  const { setCreditRisks } = useRiskStore();
  const token = localStorage.getItem("admin-token");
  const [risk, setRisk] = useState({});

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/get-credit-risks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCreditRisks(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key, value) => {
    setRisk({ ...risk, [key]: value });
  };

  const update = async () => {
    setLoadingSend(true);
    try {
      const { data } = await server.post(
        `/admin-app/store-credit-risks`,
        {
          ...risk,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getData();
      setOpen(false);
      setLoadingSend(false);
    } catch (error) {
      setLoadingSend(false);
      console.log(error);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogTitle sx={{ direction: "rtl" }}>
          <div className="text23 mr0" style={{ color: "black" }}>
            إضافة مستوى مخاطرة
          </div>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            width: "50vmin",
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">مستوى المخاطرة</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="name"
                  value={risk.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">النسبة</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="value"
                  value={risk.value}
                  onChange={(e) => handleChange("value", e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", direction: "rtl" }}>
          <LoadingButton
            variant="contained"
            size="large"
            color="info"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#727272",
            }}
            onClick={handleClose}
          >
            <div className="text3">رجوع</div>
          </LoadingButton>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              // width: "10vw",
              bgcolor: "#014E97",
            }}
            onClick={update}
          >
            <div className="text3">ارسال</div>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
