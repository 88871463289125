import { create } from "zustand";

export const useUserStor = create((set) => ({
  isOwner: 0,
  phone: null,
  setPhone: (newPhone) => set((state) => ({ ...state, phone: newPhone })),

  uuid: null,
  setUuid: (uuid) => set((state) => ({ ...state, uuid })),

  otpData: null,
  setOtpData: (otpData) => set((state) => ({ ...state, otpData })),

  userData: null,
  setUserData: (userData) => set((state) => ({ ...state, userData })),

  adminData: null,
  setAdminData: (adminData) => set((state) => ({ ...state, adminData })),

  token: null,
  setToken: (token) => set((state) => ({ ...state, token })),

  ticket: {},
  setTicket: (ticket) => set((state) => ({ ...state, ticket })),

  dashboardData: {},
  setDashboardData: (dashboardData) =>
    set((state) => ({ ...state, dashboardData })),
}));

export const useReqStore = create((set) => ({
  req: {},
  setReq: (req) => set((state) => ({ ...state, req })),
  fees: null,
  setFees: (fees) => set((state) => ({ ...state, fees })),
}));

export const useNavStore = create((set) => ({
  nav: { text: "لوحة المعلومات" },
}));

const updateObjectById = (array, idToUpdate, updatedFields) =>
  array.map((obj) =>
    obj.id === idToUpdate ? { ...obj, ...updatedFields } : obj
  );

export const useNotificationStore = create((set) => ({
  notification: [],
  setNotifications: (data) =>
    set((state) => ({ ...state, notifications: data })),
  updateRead: (id) =>
    set((state) => ({
      ...state,
      notification: updateObjectById(state.notifications, id, {
        is_read: 1,
      }),
    })),
}));

export const useRiskStore = create((set) => ({
  risk: {},
  setRisk: (data) => set((state) => ({ ...state, risk: data })),
  creditRisks: [],
  setCreditRisks: (data) => set((state) => ({ ...state, creditRisks: data })),
}));
export const useUsersStore = create((set) => ({
  user: {},
  setUser: (data) => set((state) => ({ ...state, user: data })),
}));
