import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStor, useNavStore } from "../../../store";
import { showDate } from "../../utils/common";
import server from "../../../api/server";
import { LoadingButton } from "@mui/lab";

const TicketDetails = () => {
  const { ticket, setTicket } = useUserStor();
  const date = showDate(ticket.created_at);
  const dob = showDate(ticket.dob);
  const { id } = useParams();
  const token = localStorage.getItem("admin-token");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getTicket = async () => {
    try {
      const { data } = await server.get(`/admin-app/show-ticket/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTicket(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const updateTicket = async () => {
    setLoading(true);
    try {
      const { data } = await server.post(
        `/admin-app/update-tickets`,
        {
          ticket_id: id,
          status_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTicket(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const completeTicket = async () => {
    setLoading(true);
    try {
      const { data } = await server.post(
        `/admin-app/update-tickets`,
        {
          ticket_id: id,
          status_id: 3,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTicket(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: `التذكرة #${ticket.serial_no}`,
        items: [{ text: "الشكاوى والدعم الفني", path: "/admin/support" }],
      },
    });
    if (!ticket) {
      navigate("/admin/support");
    }
    getTicket();
  }, []);

  return (
    <div style={{ width: "100%", gap: "20px", display: "grid" }}>
      {ticket.id ? (
        <>
          <div className="box10">
            <div className="box11">
              <div className="text8 center">التذكرة {ticket.id}#</div>
            </div>
          </div>
          <div className="box53">
            <Grid container>
              <Grid item xs={3}>
                <div className="box11">
                  <div className="text9 center">رقم التذكرة</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {ticket.serial_no}#
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="box11">
                  <div className="text9 center">تاريخ الإنشاء</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {date}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="box11">
                  <div className="text9 center">حالة التذكرة</div>
                  <div className="text9" style={{ marginTop: "5%" }}>
                    {ticket.status.name}
                  </div>
                </div>
              </Grid>
              {ticket.status.id === 1 && (
                <Grid item xs={3}>
                  <div className="box11">
                    <LoadingButton
                      loading={loading}
                      onClick={updateTicket}
                      variant="contained"
                      color="warning"
                      sx={{ fontFamily: "font-light" }}
                    >
                      قيد المعالجة
                    </LoadingButton>
                  </div>
                </Grid>
              )}
              {ticket.status.id === 2 && (
                <Grid item xs={3}>
                  <div className="box11">
                    <LoadingButton
                      loading={loading}
                      onClick={completeTicket}
                      variant="contained"
                      color="success"
                      sx={{ fontFamily: "font-light" }}
                    >
                      تمت المعالجة
                    </LoadingButton>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="box53">
            <div className="text8">العنوان</div>
            <div className="text8 text55">{ticket.title}</div>
            <div className="text8">وصف المشكلة</div>
            <div className="box56">
              <div className="text11 text56">{ticket.description}</div>
            </div>
            <div className="text8">الصورة المرفقة</div>
            <Box
              sx={{
                p: 1,
                borderRadius: "6px",
                border: "1px solid var(--form-stroke, #E7E7E7)",
                width: "max-content",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {ticket.files[0] ? (
                <>
                  <img
                    src={`https://api.qistar.sa/storage/${ticket.files[0].file_path}`}
                    width={100}
                    style={{ borderRadius: "4px" }}
                  />
                  <Button
                    variant="contained"
                    sx={{ fontFamily: "font-light", bgcolor: "#014E97" }}
                    onClick={() =>
                      window.open(
                        `https://api.qistar.sa/storage/${ticket.files[0].file_path}`
                      )
                    }
                  >
                    تحميل
                  </Button>
                </>
              ) : (
                <div className="text23 mr0">لا توجد</div>
              )}
            </Box>
          </div>
          <div className="box53" style={{ marginBottom: 50 }}>
            <div className="text8">بيانات العميل</div>
            <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <div className="text23 mr0">الاسم الكامل</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {ticket.customer?.name || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text23 mr0">تاريخ الميلاد</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">{dob}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="text23 mr0"> البريد الإلكتروني</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {ticket.customer?.email || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text23 mr0"> رقم الجوال</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {ticket.customer?.phone || "غير موجود"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default TicketDetails;
