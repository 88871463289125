import { Grid, CircularProgress, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import server from "../../../api/server";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Activities = ({ userType }) => {
	const [loading, setLoading] = useState({});
	const [loadingData, setLoadingData] = useState(true);
	const [data, setData] = useState();

	const localToken = localStorage.getItem("admin-token");
	const navigate = useNavigate();

	const getData = async () => {
		try {
			const { data } = await server.get("/admin-app/get-activities", {
				headers: {
					Authorization: `Bearer ${localToken}`,
				},
			});
			console.log(data);
			setData(data.data);
			setLoadingData(false);
		} catch (error) {
			console.log(error);
		}
	};

	const goToApplication = async (link, id) => {
		setLoading({ [id]: true });
		try {
			const { data } = await server.get(`${link}`, {
				headers: {
					Authorization: `Bearer ${localToken}`,
				},
			});
			console.log(data.data[0].application_status_id);
			// setLoading({ [link]: false });
			localStorage.setItem("req", JSON.stringify(data.data[0]));
			if (data.data[0].application_status_id) {
				navigate(
					`/admin/request/${data.data[0].application_status_id}`
				);
			}
		} catch (error) {
			console.log(error);
			setLoading({ [id]: false });
		}
	};

	const appIsCanceled = (link) => {
		return false;
		// if (link.includes("قمت بالغاء")) return true;
	};

	useEffect(() => {
		getData();
	}, []);

	const handleShow = (item) => {
		if (item.title.includes("جديد")) {
			goToApplication(item.link, item.id);
		}
	};

	const renderData = () => {
		return data?.slice(0, 4).map((item) => {
			return (
				<Grid xs={12} key={item.id}>
					<div className="box3">
						<div className="text14 f-normal">{item.details}</div>
						{!appIsCanceled(item.details) ? (
							<LoadingButton
								loading={loading[item.id]}
								onClick={() => handleShow(item)}
							>
								<div className="text13">عرض</div>
							</LoadingButton>
						) : (
							<LoadingButton
								loading={loading[item.id]}
								sx={{ "&:hover": { background: "#fff" } }}
								disabled
							>
								<div
									className="text13"
									style={{ color: "#fff" }}
								>
									عرض
								</div>
							</LoadingButton>
						)}
					</div>
					<Divider sx={{ mb: "3px", mt: "3px" }} />
				</Grid>
			);
		});
	};

	return (
		<>
			{loadingData ? (
				<CircularProgress />
			) : (
				<>
					{data?.length === 0 ? (
						<div>لاتوجد أنشطة</div>
					) : (
						renderData()
					)}
				</>
			)}
		</>
	);
};

export default Activities;
