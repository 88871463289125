import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import success from "../../../assets/offer.svg";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useReqStore } from "../../../store";
import { useState } from "react";
import { DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export default function SendOwnerOffer({ open, setOpen, appId, token }) {
	const [loadingSendOffer, setLoadingSendOffer] = useState(false);

	const navigate = useNavigate();
	const { setReq } = useReqStore();

	const sendOffer = async () => {
		setLoadingSendOffer(true);
		try {
			const { data } = await server.post(
				"/admin-app/send-owner-offer",
				{
					application_id: `${appId}`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(data);
			setLoadingSendOffer(false);
			localStorage.setItem("req", JSON.stringify(data.data));
			setOpen(false);
		} catch (error) {
			console.log(error);
			setLoadingSendOffer(false);
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleAgeeAndContinue = () => {
		sendOffer();
	};

	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				className="scrollbar2"
			>
				<DialogContent
					dividers
					sx={{
						direction: "rtl",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						padding: "1vmax",
						width: "50vmin",
					}}
				>
					<img
						src={success}
						alt="success"
						style={{ width: "5vmax" }}
					/>
					<div style={{ display: "flex", marginTop: "10px" }}>
						<div style={{ fontSize: "1.5vmax" }}>
							هل تريد إرسال العرض للمالك؟
						</div>
					</div>
				</DialogContent>
				<DialogActions
					sx={{ justifyContent: "flex-end", direction: "rtl" }}
				>
					<LoadingButton
						variant="contained"
						size="large"
						color="info"
						sx={{
							marginTop: "10px",
							marginLeft: "10px",
							// width: "10vw",
							bgcolor: "#727272",
						}}
						onClick={handleClose}
					>
						<div className="text3">رجوع</div>
					</LoadingButton>
					<LoadingButton
						loading={loadingSendOffer}
						variant="contained"
						size="large"
						sx={{
							marginTop: "10px",
							marginLeft: "10px",
							// width: "10vw",
							bgcolor: "#014E97",
						}}
						onClick={sendOffer}
					>
						<div className="text3">إرسال عرض</div>
					</LoadingButton>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
}
