import { Grid, Button, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import { useNavStore } from "../../../store";
import { Suspense, lazy, useEffect } from "react";
import server from "../../../api/server";
const CustomPaginationActionsTable = lazy(() => import("./DataGrid"));

const AdminContracts = () => {
  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "إدارة العقود",
      },
    });
  }, []);

  return (
    <div>
      <Grid item xs={12} sx={{ direction: "rtl" }}>
        <div
          className="box10"
          style={{
            display: "flex",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div className="box11" style={{ width: "100%" }}>
            <div className="text8 center">إدارة العقود</div>
          </div>
        </div>
      </Grid>
      <div className="box14" style={{ margin: "2rem", height: "auto" }}>
        <div className="box10 box50">
          <div className="box11 box51">
            <SearchBar />
            <Button
              variant="contained"
              size="small"
              sx={{
                marginRight: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
                height: "45px",
              }}
            >
              <div className="text3">بحث</div>
            </Button>
          </div>
        </div>
        {/* <DataTable /> */}
        <div
          style={{
            backgroundColor: "#F4F7FF",
            padding: 10,
            borderRadius: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Suspense
            fallback={
              <div className="loading">
                <CircularProgress />
              </div>
            }
          >
            <CustomPaginationActionsTable noPagination />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AdminContracts;
