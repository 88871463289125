import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
const SideNavigator = ({
  partref,
  propertyref,
  installmentRef,
  depositRef,
}) => {
  const [open, setOpen] = React.useState({ 1: true });

  const handleClick = (id, ref) => {
    setOpen(
      Object.keys(open).forEach((key) => {
        open[key] = false;
      })
    );
    setOpen({ ...open, [id]: !open[id] });
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const routes = [
    {
      page: "تفاصيل العقد",
      id: 1,
      ref: partref,
    },
    {
      page: "معلومات الأطراف",
      id: 2,
      ref: partref,
    },
    {
      page: "معلومات العقار",
      id: 3,
      ref: propertyref,
    },
    {
      page: "الأقساط",
      id: 4,
      ref: installmentRef,
    },
    {
      page: "الإيرادات للمالك",
      id: 5,
      ref: depositRef,
    },
  ];

  const sidebarItems = () => {
    return routes.map(({ id, page, ref }) => {
      const openList = open[id] || false;

      const active = () => {
        return openList ? "active-nav" : "reverse-nav";
      };
      return (
        <div key={id}>
          <ListItemButton
            className={active()}
            onClick={() => handleClick(id, ref)}
            sx={{
              ".MuiTypography-root": {
                fontFamily: "inherit",
                fontSize: "0.8rem",
              },
            }}
          >
            <ListItemText className={`direction `} primary={page} />
          </ListItemButton>
        </div>
      );
    });
  };

  return (
    <>
      <div
      // className={`sideBar ${sideBarMain} sidebar-mobile`}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "linear-gradient(180deg, #004A98 0%, #350055 100%);",
            right: "0px",
            direction: "rtl",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {sidebarItems()}
        </List>
      </div>
    </>
  );
};

export default SideNavigator;
