import { useEffect, useState } from "react";
import server from "../../../api/server";
import CustomPaginationActionsTable from "./DataGrid";
import { LoadingButton } from "@mui/lab";
import { PersonAdd } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("admin-token");
  const navigate = useNavigate();
  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/get-users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "auto" }}>
          <div className="text8 center" style={{ marginRight: "0%" }}>
            إدارة المستخدمين
          </div>
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LoadingButton
            variant="contained"
            size="large"
            sx={{
              marginLeft: "10px",
              width: "max-content",
              bgcolor: "#014E97",
              height: "auto",
              gap: 1,
            }}
            onClick={() => navigate("/admin/users/add")}
          >
            <div className="text3">إضافة مستخدم</div>
            <PersonAdd fontSize="small" />
          </LoadingButton>
        </Box>
      </div>
      <div
        className="box14"
        style={{
          margin: "2rem",
          height: "auto",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F7FF",
            padding: 10,
            borderRadius: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          <CustomPaginationActionsTable rows={data} noPagination />
        </div>
      </div>
    </div>
  );
};

export default Users;
