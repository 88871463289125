import { Suspense, lazy, useEffect, useState } from "react";
import server from "../../../api/server";
import { useNavStore, useRiskStore } from "../../../store";
import { Box, CircularProgress } from "@mui/material";
import UpdatePopup from "./UpdatePopup";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import AddPopup from "./AddPopup";
const CreditRiskMap = lazy(() => import("./CreditRiskMap"));

const CreditRisk = () => {
  const token = localStorage.getItem("admin-token");
  const { risk, setRisk } = useRiskStore();
  const [riskOpenPopup, setRiskOpenPopup] = useState(false);
  const [addRiskOpenPopup, setAddRiskOpenPopup] = useState(false);

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "مستويات المخاطرة",
      },
    });
    // getData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <UpdatePopup open={riskOpenPopup} setOpen={setRiskOpenPopup} />
      <AddPopup open={addRiskOpenPopup} setOpen={setAddRiskOpenPopup} />
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center" style={{ marginRight: "0%" }}>
            مستويات المخاطرة
          </div>
        </div>
      </div>
      <Box
        sx={{
          direction: "rtl",
          background: "#fff",
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Suspense
          fallback={
            <div className="loading">
              <CircularProgress />
            </div>
          }
        >
          <CreditRiskMap setRisk={setRisk} openPopup={setRiskOpenPopup} />
        </Suspense>
        <Box>
          <LoadingButton
            // loading={loadingUpdate}
            variant="contained"
            size="large"
            sx={{
              marginLeft: "10px",
              width: "max-content",
              bgcolor: "#014E97",
              height: "auto",
              paddingInline: 10,
            }}
            onClick={() => setAddRiskOpenPopup(true)}
          >
            <Add fontSize="small" sx={{ ml: 1 }} />
            <div className="text3">اضافة</div>
          </LoadingButton>
        </Box>
      </Box>
    </div>
  );
};

export default CreditRisk;
