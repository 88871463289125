import { Button, Grid, TextField } from "@mui/material";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { PhoneInput } from "react-international-phone";
import "./AdminLogin.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useUserStor } from "../../store";
import server from "../../api/server";
import "react-international-phone/style.css";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [focused, setFocused] = useState(null);
  const [loading, setloading] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const { setOtpData } = useUserStor();

  const localToken = localStorage.getItem("admin-token");

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/user", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      navigate("/admin/home");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnclickContinue = async () => {
    setloading(true);
    setIsNotValid(false);
    try {
      const res = await server.post("/admin-app/get-otp", {
        phone: phoneNumber.substring(1),
        user_name: userName,
      });
      console.log(res.data);
      // alert(res.data.data.otp);
      setOtpData(res.data.data);
      navigate("/admin/otp");
    } catch {
      console.log("something wrong");
      setloading(false);
      setIsNotValid(true);
    }
  };

  const handleOnfocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleOnclickContinue = () => {
  //   navigate("/admin/otp");
  // };

  const TextLabel = ({ children }) => {
    const LabelText = useMemo(() => {
      // console.log(focused);
      return (
        <div
          className="text2"
          style={{ color: focused ? "#004A98" : "#4F4F4F" }}
        >
          {children}
        </div>
      );
    }, [focused]);
    return LabelText;
  };

  const showErrorText = () => {
    return (
      isNotValid && (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          اسم المستخدم أو رقم الهاتف عير صحيح
        </div>
      )
    );
  };

  return (
    <div
      style={{
        // backgroundImage: `url(${image22})`,
        backgroundSize: "cover",
        height: "100vh",
        // width: "100vw",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            bgcolor: "#E7E7E7",
          }}
        >
          <div className="logIn">
            <img src={image2} className="image2" alt="Qistar" />
            <div className="text1">تسجيل الدخول</div>
            <TextLabel>الأسم:</TextLabel>
            <TextField
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              onFocus={handleOnfocus}
              onBlur={handleOnBlur}
              onChange={(e) => setUserName(e.target.value)}
            />
            {/* <TextLabel>رقم الجوال</TextLabel>
            <TextField
              sx={{
                ":focus-within": {
                  backgroundColor: "#F1F4FF",
                },
                width: "100%",
                direction: "rtl",
                color: "GrayText",
                fontWeight: "700px",
              }}
              className="textInput1"
              variant="outlined"
              onFocus={handleOnfocus}
              onBlur={handleOnBlur}
            /> */}
            {/* <Button
							variant="contained"
							sx={{ backgroundColor: "#004A98", width: "100%" }}
							onClick={handleOnclickContinue}
						>
							<div className="text3"> متابعة</div>
						</Button> */}
            <PhoneInput
              inputClassName="c-input"
              defaultCountry="sa"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              style={{ width: "100%" }}
            />
            {showErrorText()}
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{
                backgroundColor: "#004A98",
                width: "100%",
              }}
              onClick={handleOnclickContinue}
            >
              <div className="text3"> متابعة</div>
            </LoadingButton>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            bgcolor: "linear-gradient(180deg, #004A98 0%, #350055 100%);",
          }}
          className="bg"
        >
          <img className="image1" src={image1} alt="Qistar" />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminLogin;
