import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SideBar from "./Sidebar";
import StatusBar from "./StatusBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import "./HomeLayout.css";
import { useUserStor } from "../../../store";

const AdminLayout = ({ element }) => {
  const [drawer, setDrawer] = useState(false);
  const [toggleSidebar, setToggleSeidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setAdminData } = useUserStor();

  const localToken = localStorage.getItem("admin-token");

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/user", {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setAdminData(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  useEffect(() => {
    if (localToken) {
      getData();
    } else {
      navigate("/");
    }
  }, []);

  const toggleDrawer = (open) => {
    setDrawer(open);
    console.log(drawer);
  };

  const sidebarGrid = () => {
    return toggleSidebar ? 2.3 : 0.8;
  };

  const StatusBarGrid = () => {
    return toggleSidebar ? 9.7 : 11.2;
  };

  return (
    <div>
      <div>
        <Grid
          direction={"row-reverse"}
          container
          sx={{
            height: "100vh",
            alignItems: "flex-start",
            backgroundColor: "#F4F7FF",
          }}
        >
          {/* The side bar */}
          <Grid item xs={sidebarGrid()}>
            <SideBar
              sideBarMain="sideBar-main"
              expand={toggleSidebar}
              setExpand={setToggleSeidebar}
            />
            <Button className="menu-button" onClick={() => toggleDrawer(true)}>
              <MenuIcon fontSize="inherit" className="menu-icon" />
            </Button>
            <Drawer
              anchor="left"
              open={drawer}
              onClose={() => toggleDrawer(false)}
            >
              <Box sx={{ width: "500" }} role="presentation">
                <SideBar />
              </Box>
            </Drawer>
          </Grid>
          {/* status bar */}

          {/* render routes */}
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid
              item
              xs={12}
              md={StatusBarGrid()}
              lg={StatusBarGrid()}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                //   justifyContent: "flex-start",
                backgroundColor: "rgb(244, 247, 255)",
              }}
            >
              <StatusBar />
              <div
                style={{
                  backgroundColor: "#F4F7FF",
                  minHeight: "-webkit-fill-available",
                  display: "flex",
                  justifyContent: "center",
                  marginInline: "40px",
                }}
              >
                {element}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default AdminLayout;
