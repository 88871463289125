import { Button, Grid, CircularProgress } from "@mui/material";
import icon1 from "../../../assets/Group.png";
import PieChartWithCenterLabel from "./PieChart";
import icon2 from "../../../assets/icon1.png";
import icon3 from "../../../assets/icon3.png";
import icon4 from "../../../assets/donl.png";
import "./admin.css";
import DataTable from "../AdminReq/DataTable";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { useState, useEffect } from "react";
import CustomPaginationActionsTable from "../AdminReq/DataGrid";
import Inbox from "./Inbox";
import Activities from "./Activities";
import { useNavStore } from "../../../store";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loadingAppChart, setLoadingAppChat] = useState(true);
  const [appComplete, setAppComplete] = useState(null);
  const [appPending, setAppPending] = useState(null);
  const [appCanceled, setAppCanceled] = useState(null);

  const token = localStorage.getItem("admin-token");

  const contractData = [
    { value: 7, label: "النشطه", color: "#27AE60" },
    { value: 3, label: "قيد المعالجة", color: "#FFC300" },
  ];
  const reqData = [
    { value: appComplete, label: "المكتملة", color: "#27AE60" },
    { value: appPending, label: "قيد المعالجة ", color: "#FFC300" },
    { value: appCanceled, label: "الملغي", color: "#FF1F00" },
  ];

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/applications", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAppStatisticts = async () => {
    setAppComplete(0);
    setAppPending(0);
    setAppCanceled(0);
    try {
      const { data } = await server.get("/admin-app/applications-statistics", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // setStatisticsApp(data.data);
      setLoadingAppChat(false);
      calculateAppStatistics(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateAppStatistics = (arr) => {
    arr.map((obj) => {
      if (obj.id < 6) {
        setAppPending((prev) => prev + obj.count);
      } else if (obj.id === 6) {
        setAppComplete(obj.count);
      } else if (obj.id == 7) {
        setAppCanceled(obj.count);
      }
    });
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "لوحة المعلومات",
      },
    });
    getData();
    getAppStatisticts();
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"space-around"}
        spacing={2}
        sx={{ display: "flex", direction: "rtl", mt: "0px" }}
        style={{ marginTop: "50px" }}
        mt={"0px"}
      >
        {/* <Grid xs={12}>
              <div style={{ marginRight: "50px", fontFamily: "font" }}>
                أهلا بك , محمد 👋
              </div>
            	</Grid> */}
        <Grid item xs={6} display={"flex"}>
          <div
            className="admin-box1"
            style={{ alignItems: "flex-start", padding: "10px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                marginRight: "10px",
                marginTop: 10,
              }}
            >
              <img
                src={icon1}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text8" style={{ marginRight: 5 }}>
                العقود
              </div>
            </div>
            <div className="border" style={{ marginBlock: 10 }}></div>
            <Grid
              container
              direction={"row"}
              display={"flex"}
              sx={{ mr: 1, mt: 1 }}
            >
              <Grid item xs={5}>
                <div style={{ display: "flex" }}>
                  <div className="shape1"></div>
                  <p className="text9">النشطه : 7</p>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="shape2"></div>
                  <p className="text9">قيد المعالجة : 3 </p>
                </div>
              </Grid>
              <Grid
                item
                xs={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <PieChartWithCenterLabel data={contractData} />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} display={"flex"}>
          <div
            className="admin-box1"
            style={{ alignItems: "flex-start", padding: "10px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                marginRight: "10px",
                marginTop: 10,
              }}
            >
              <img
                src={icon4}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text8" style={{ marginRight: 5 }}>
                الطلبات
              </div>
            </div>
            <div className="border" style={{ marginBlock: 10 }}></div>
            <Grid
              container
              direction={"row"}
              display={"flex"}
              sx={{ mr: 1, mt: 1 }}
            >
              {loadingAppChart ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Grid item xs={5}>
                    <Grid item xs={12} display={"flex"}>
                      <div className="shape1"></div>
                      <p className="text9">المكتملة : {appComplete}</p>
                    </Grid>
                    <Grid item xs={12} display={"flex"}>
                      <div className="shape2"></div>
                      <p className="text9">قيد المعالجة : {appPending} </p>
                    </Grid>
                    <Grid item xs={12} display={"flex"}>
                      <div className="shape4"></div>
                      <p className="text9">الملغي : {appCanceled} </p>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <PieChartWithCenterLabel data={reqData} />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>
        {/* <Grid item xs={4} display={"flex"} sx={{ marginLeft: "0px" }}>
          <div
            className="admin-box1"
            style={{ alignItems: "flex-start", padding: 10 }}
          >
            <p className="text8">القسط الحالي</p>
            <Grid item xs={12} display={"flex"}>
              <p className="text10">العقد:</p>
              <p className="text10">EJAR-RES-11012345697</p>
            </Grid>
            <div
              className="box2"
              style={{
                padding: "5px",
                marginRight: 0,
                width: "auto",
              }}
            >
              <Grid item xs={4}>
                <p className="text11">التاريخ المحدد للدفع </p>
                <p className="text12">31-09-2023</p>
              </Grid>
              <Grid item xs={4}>
                <p className="text11">قيمة القسط</p>
                <p className="text12">8500 ريال سعودي</p>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{
                    marginTop: "15%",
                    marginRight: "10%",
                  }}
                >
                  <div className="text13">إرسال تذكير</div>
                </Button>
              </Grid>
            </div>
          </div>
        </Grid> */}
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        spacing={2}
        sx={{ display: "flex", direction: "rtl", mt: 1 }}
      >
        <Grid item xs={6} display={"flex"}>
          <div className="admin-box1" style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginRight: "10px",
                marginTop: 10,
              }}
            >
              <img
                src={icon2}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div
                className="text8"
                style={{ marginRight: 5, marginBottom: 10 }}
              >
                صندوق الوارد
              </div>
            </div>
            <Grid container>
              <Inbox />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} display={"flex"}>
          <div className="admin-box1" style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginRight: "10px",
                marginTop: 10,
                // padding: "10px",
              }}
            >
              <img
                src={icon3}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div
                className="text8"
                style={{ marginRight: 5, marginBottom: 10 }}
              >
                الأنشطة الاخيرة
              </div>
            </div>
            <Grid container>
              <Activities />
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="box1"
          style={{
            height: "auto",
            minWidth: "auto",
            borderRadius: "8px",
          }}
        >
          <div
            className="box10"
            style={{
              display: "flex",
              // justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box-2">
              <div
                className="box11"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginInline: 2,
                  }}
                />
                <div className="text8 center m0">الطلبات الاخيرة</div>
              </div>
              <Button onClick={() => navigate("/admin/requests")}>
                <div className="text13">عرض الكل</div>
              </Button>
            </div>
          </div>
          {/* table */}
          <div
            style={{
              backgroundColor: "#F4F7FF",
              padding: 10,
              borderRadius: 8,
              width: "100%",
            }}
          >
            {data ? (
              <CustomPaginationActionsTable noPagination rows={data} hide />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
