import { useParams } from "react-router-dom";
import server from "../../../api/server";
import { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Grid, Snackbar } from "@mui/material";
import { showDate } from "../../utils/common";
import { LoadingButton } from "@mui/lab";
import profile from "../../../assets/blank-profile-picture-973460_960_720.webp";

const CustomerDetails = () => {
  const token = localStorage.getItem("admin-token");
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const dob = customer.dob ? showDate(customer.dob) : "غير موجود";
  const [loading, setLoading] = useState(false);
  const [loadingLoguot, setLoadingLoguot] = useState(false);
  const [logoutFeedback, setLogoutFeedback] = useState(false);

  const getCustomer = async () => {
    try {
      const { data } = await server.get(`admin-app/customers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const acivateCustomer = async () => {
    setLoading(true);
    try {
      const { data } = await server.post(
        `admin-app/update-customers`,
        {
          phone: customer.phone,
          status: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomer(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const deAcivateCustomer = async () => {
    setLoading(true);
    try {
      const { data } = await server.post(
        `admin-app/update-customers`,
        {
          phone: customer.phone,
          status: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomer(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const logoutCustomer = async () => {
    setLoadingLoguot(true);
    try {
      const { data } = await server.post(
        `admin-app/logout-customers`,
        {
          phone: customer.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomer(data.data);
      setLoadingLoguot(false);
      setLogoutFeedback(true);
    } catch (error) {
      console.log(error);
      setLoadingLoguot(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLogoutFeedback(false);
  };

  const renderIdType = (type) => {
    if (!type) return "لا يوجد";
    if (type === 1) {
      return "هوية وطنية";
    } else if (type === 2) {
      return "إقامة مميزة";
    } else if (type === 3) {
      return "هوية مقيم";
    }
  };
  const renderStatus = () => {
    if (customer.is_active === 0) {
      return (
        <div
          className="text12 mr0 f-normal"
          style={{ color: "#FF1F00", marginTop: 10 }}
        >
          غير نشط
        </div>
      );
    } else if (customer.is_active === 1) {
      return (
        <div
          className="text12 mr0 f-normal"
          style={{ color: "green", marginTop: 10 }}
        >
          نشط
        </div>
      );
    }
  };
  useEffect(() => {
    getCustomer();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      {customer.id ? (
        <>
          <Snackbar
            open={logoutFeedback}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              <div className="text3">تم تسجيل الخروج</div>
            </Alert>
          </Snackbar>
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 20,
            }}
          >
            <div className="box11" style={{ width: "auto" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                حساب عميل
              </div>
            </div>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LoadingButton
                loading={loadingLoguot}
                variant="contained"
                size="large"
                sx={{
                  marginLeft: "10px",
                  width: "max-content",
                  bgcolor: "#014E97",
                  height: "auto",
                }}
                onClick={logoutCustomer}
              >
                <div className="text3">تسجيل خروج العميل</div>
              </LoadingButton>
            </Box>
          </div>
          <Box
            sx={{
              width: "auto",
              bgcolor: "white",
              direction: "rtl",
              p: 2,
              mt: 3,
              borderRadius: "8px",
            }}
          >
            <Grid container direction={"row"} sx={{ marginTop: "10px", mb: 2 }}>
              <Grid item xs={6}>
                <div className="text23 mr0">حالة الحساب</div>
                {renderStatus()}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {customer.is_active === 0 ? (
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="large"
                    sx={{
                      marginLeft: "10px",
                      width: "10vw",
                      bgcolor: "#014E97",
                    }}
                    onClick={acivateCustomer}
                  >
                    <div className="text3">تنشيط</div>
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="large"
                    color="error"
                    sx={{
                      marginLeft: "10px",
                      width: "10vw",
                    }}
                    onClick={deAcivateCustomer}
                  >
                    <div className="text3">إلغاء التنشيط</div>
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "auto",
              bgcolor: "white",
              direction: "rtl",
              p: 2,
              mt: 3,
              borderRadius: "8px",
            }}
          >
            <div className="text8" style={{ marginBottom: 10 }}>
              بيانات العميل
            </div>
            {customer.profile_pic ? (
              <img
                alt="profile"
                src={`https://api.qistar.sa/storage/${customer.profile_pic}`}
                className="pro-img"
              />
            ) : (
              <img alt="profile" src={profile} className="pro-img" />
            )}
            <Grid container direction={"row"} sx={{ marginTop: "10px", mb: 2 }}>
              <Grid item xs={6}>
                <div className="text23 mr0">الاسم الكامل</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.name || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0">تاريخ الميلاد</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">{dob}</div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="text23 mr0"> البريد الإلكتروني</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.email || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0"> رقم الجوال</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.phone || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0"> نوع الهوية</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {renderIdType(customer.identity_type_id)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0"> رقم الهوية</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.identity_number || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text23 mr0"> رقم الأيبان</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.iban || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text23 mr0"> العنوان</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer?.address || "غير موجود"}
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="text8">العنوان الوطني</div>
            <Grid container direction={"row"} sx={{ marginTop: "10px" }}>
              <Grid item xs={6}>
                <div className="text23 mr0">المدينة</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer.national_address?.cityName || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0">الرمز المختصر</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer.national_address?.districtCode || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0">الحي</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer.national_address?.districtName || "غير موجود"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text23 mr0">رقم المبنى</div>
                <div
                  className="box15"
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                    fontFamily: "font-normal",
                  }}
                >
                  <div className="text12 mr0 f-normal">
                    {customer.national_address?.landNumber || "غير موجود"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
