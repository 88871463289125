import { Suspense, lazy, useEffect, useState } from "react";
import server from "../../../api/server";
import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import { useNavStore } from "../../../store";
import { LoadingButton } from "@mui/lab";
const CustomPaginationActionsTable = lazy(() => import("./DataGrid"));

const Memorandum = () => {
  const token = localStorage.getItem("admin-token");
  const [serial_no, setSerial_no] = useState(null);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/memorandum-understanding", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const filterCustomers = async () => {
    try {
      const { data } = await server.get(
        `/admin-app/memorandum-understanding?serial_no=${serial_no}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "مذكرة تفاهم",
      },
    });
    getData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center">مذكرة تفاهم</div>
        </div>
      </div>
      <div className="box14" style={{ margin: "2rem", height: "auto" }}>
        <Box sx={{ width: "100%", direction: "rtl", marginBlock: 2 }}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                name="serial_no"
                placeholder="رقم المذكرة"
                autoComplete="off"
                inputProps={{ autoComplete: "new-password" }}
                sx={{
                  fontFamily: "font-light",
                  ".MuiInputBase-input": {
                    fontFamily: "font-light !important",
                    textAlign: "right",
                  },
                }}
                onChange={(e) => setSerial_no(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <LoadingButton
                variant="contained"
                size="large"
                sx={{
                  marginLeft: "10px",
                  width: "10vw",
                  bgcolor: "#014E97",
                }}
                onClick={filterCustomers}
              >
                <div className="text3">فلترة</div>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            backgroundColor: "#F4F7FF",
            padding: 10,
            borderRadius: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Suspense
            fallback={
              <div className="loading">
                <CircularProgress />
              </div>
            }
          >
            <CustomPaginationActionsTable noPagination rows={data} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Memorandum;
