import React from "react";
import { MenuItem, Select, CircularProgress } from "@mui/material";
import server from "../../../api/server";
import { useEffect, useState } from "react";

export function RiskSelector({ risk, e, setRisk }) {
	const token = localStorage.getItem("admin-token");
	const [creditRisksData, setCreditRisksData] = useState([]);

	const getData = async () => {
		try {
			const { data } = await server.get("/admin-app/get-credit-risks", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setCreditRisksData(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const renderRisks = () => {
		return creditRisksData.map((item) => {
			return (
				<MenuItem
					key={item.id}
					value={item.id}
					sx={{
						bgColor: "#fd16169c",
						textAlign: "right",
						direction: "rtl",
					}}
				>
					<div className="text3">{item.name}</div>
				</MenuItem>
			);
		});
	};

	const onRiskChange = (e) => {
		setRisk(e.target.value);
		console.log(risk);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			{creditRisksData ? (
				<Select
					value={risk}
					onChange={(e) => onRiskChange(e)}
					placeholder="اختر مستوى المخاطرة" // label="اختر مستوى المخاطرة"
					sx={{
						direction: "rtl",
					}}
					size="small"
				>
					{renderRisks()}
				</Select>
			) : (
				<CircularProgress />
			)}
		</>
	);
}
