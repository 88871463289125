import React, { useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../../assets/image1.svg";
import { useNavigate, useLocation } from "react-router-dom";
import icon1 from "../../../assets/Frame.png";
import icon3 from "../../../assets/contratctW.png";
import icon4 from "../../../assets/complain.png";
import icon2 from "../../../assets/downlaod.png";
import icon5 from "../../../assets/lucide_chevron-down.png";
import icon6 from "../../../assets/lucide_chevron-up.png";
import { Collapse, Fab } from "@mui/material";
import {
  BusinessCenter,
  CreditCard,
  Description,
  ExpandLess,
  ExpandMore,
  GroupsRounded,
  RunningWithErrors,
  Settings,
  SupervisedUserCircle,
} from "@mui/icons-material";

const SideBar = ({ sideBarMain, expand, setExpand }) => {
  const [open, setOpen] = React.useState({});
  const location = useLocation();

  // useEffect(() => {
  //   setOpen({ ...open, [location.pathname]: true });
  //   if (location.pathname.includes("/home/settings")) {
  //     setOpen({ ...open, 3: true });
  //   } else if (location.pathname.includes("/home/support")) {
  //     setOpen({ ...open, 5: true });
  //   } else if (location.pathname.includes("/home/users")) {
  //     setOpen({ ...open, 4: true });
  //   }
  // }, []);

  let navigate = useNavigate();

  const handleClick = (id, path, subPage) => {
    setOpen(
      Object.keys(open).forEach((key) => {
        open[key] = false;
      })
    );
    setOpen({ ...open, [id]: !open[id] });
    if (!subPage) {
      navigate(id);
    }
    console.log(open);
  };

  const routes = [
    {
      page: "لوحة المعلومات",
      id: "/admin/home",
      icon: <img src={icon1} alt="icon" />,
    },
    {
      page: "إداره الطلبات",
      id: "/admin/requests",
      icon: <img src={icon2} alt="icon" />,
    },
    {
      page: "إدارة العقود",
      id: "/admin/contracts",
      icon: <img src={icon3} alt="icon" />,
    },
    {
      page: "العملاء",
      id: "/admin/customers",
      icon: <GroupsRounded />,
      subPage: [
        {
          title: "إدارة العملاء",
          id: "/admin/customers",
          icon: <GroupsRounded />,
        },
        {
          title: "إيرادات الملاك",
          id: "/admin/customers/payment/requests",
          icon: <CreditCard />,
        },
      ],
    },
    {
      page: "الوسطاء العقاريون",
      id: "/admin/brokers",
      icon: <BusinessCenter />,
      subPage: [
        {
          title: "إدارة الوسطاء",
          id: "/admin/brokers",
          icon: <BusinessCenter />,
        },
        {
          title: "طلبات الصرف",
          id: "/admin/brokers/payment/requests",
          icon: <CreditCard />,
        },
      ],
    },
    {
      page: "مستويات المخاطرة",
      id: "/admin/credit-risks",
      icon: <RunningWithErrors />,
    },
    {
      page: "إدارة المستخدمين",
      id: "/admin/users",
      icon: <SupervisedUserCircle />,
    },
    {
      page: "مذكرة تفاهم",
      id: "/admin/memorandum",
      icon: <Description />,
    },
    {
      page: "الإعدادات",
      id: "/admin/settings",
      icon: <Settings />,
    },
    {
      page: "الشكاوى",
      id: "/admin/support",

      icon: <img src={icon4} alt="icon" />,
    },
  ];

  const shrink = () => {
    return expand ? "" : "sideBar-shrink";
  };

  const logoWidth = () => {
    return expand ? "120px" : "80px";
  };

  const sidebarItems = () => {
    return routes.map(({ id, page, icon, subPage, path }) => {
      const openList = open[id] || false;

      const active = () => {
        return openList ? "active" : "reverse";
      };

      const renderExpandBtn = () => {
        if (subPage) {
          return openList ? <ExpandLess /> : <ExpandMore />;
        } else {
          return null;
        }
      };

      const activeIcone = () => {
        return openList ? "active-icone" : "reverse-icone";
      };

      const display = () => {
        return !expand ? "displayNon" : "";
      };

      const subPages = (subpage) => {
        return subpage.map(({ title, id, icon }) => {
          return (
            <ListItemButton
              key={Math.random().toString()}
              className=""
              sx={{
                pl: 4,
                ".MuiTypography-root": {
                  fontFamily: "inherit",
                  fontSize: "0.8rem",
                },
              }}
              onClick={() => navigate(id)}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  color: location.pathname == id ? "#f9aa33" : "#fff",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: location.pathname == id ? "#f9aa33" : "#fff",
                }}
                className={`direction ${display()}`}
                primary={
                  <div style={{ fontFamily: "font-normal", marginRight: 5 }}>
                    {title}
                  </div>
                }
              />
            </ListItemButton>
          );
        });
      };

      return (
        <div key={id}>
          <ListItemButton
            className={active()}
            onClick={() => handleClick(id, path, subPage)}
            sx={{
              ".MuiTypography-root": {
                fontFamily: "inherit",
                fontSize: "0.8rem",
              },
            }}
          >
            <ListItemIcon className={activeIcone()} sx={{ width: "10px" }}>
              {icon}
            </ListItemIcon>
            <ListItemText className={`direction ${display()}`} primary={page} />
            {renderExpandBtn()}
          </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            {subPage ? (
              <List component="div" disablePadding>
                {subPages(subPage)}
              </List>
            ) : null}
          </Collapse>
        </div>
      );
    });
  };

  return (
    <>
      <div className={`sideBar ${sideBarMain} ${shrink()}`}>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "linear-gradient(180deg, #004A98 0%, #350055 100%);",
            right: "0px",
            direction: "rtl",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{ bgcolor: "transparent" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  // maxWidth: "-webkit-fill-available",
                  marginLeft: "8vw",
                  marginTop: "20px",
                  width: logoWidth(),
                }}
              />
            </ListSubheader>
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Fab
              size="small"
              sx={{
                minHeight: "20px",
                height: "20px",
                width: "20px",
                ml: "-7px",
                bgcolor: "#fff",
              }}
              onClick={() => setExpand((state) => !state)}
            >
              {expand ? (
                <img src={icon5} alt="i" />
              ) : (
                <img src={icon6} alt="i" />
              )}
            </Fab>
          </div>
          {sidebarItems()}
        </List>
      </div>
    </>
  );
};

export default SideBar;
