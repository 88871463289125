import { Button, Grid } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useUserStor } from "../../store";
import server from "../../api/server";

const AdminOTP = () => {
	const [otp, setOtp] = useState("");
	const navigate = useNavigate();
	const { otpData, setAdminData, setToken, setOtpData } = useUserStor();
	const [error, setError] = useState(false);

	const verify = async () => {
		setError(false);
		try {
			const { data } = await server.post("/admin-app/verify-otp", {
				...otpData,
				otp,
			});

			setAdminData(data.data.user);
			setToken(data.data.token);
			localStorage.setItem("admin-token", data.data.token);
			localStorage.setItem("admin", JSON.stringify(data.data.user));

			navigate("/admin/home");

			console.log(data.data);
		} catch (error) {
			console.log(error.message);
			setError(true);
		}
	};

	const resendOTP = async () => {
		try {
			const res = await server.post("/admin-app/get-otp", {
				phone: otpData.phone,
			});
			console.log(res.data);
			setOtpData(res.data.data);
			alert(res.data.data.otp);
		} catch {
			console.log("something wrong");
		}
	};

	const showError = () => {
		return (
			error && (
				<div
					className="text2"
					style={{ color: "red", marginTop: "0px" }}
				>
					رمز التحقق غير صحيح
				</div>
			)
		);
	};

	// navigation to next page
	useEffect(() => {
		if (otp.length === 5) {
			verify();
		}
	}, [otp, navigate]);

	return (
		<div
			style={{
				backgroundSize: "cover",
				height: "100vh",
			}}
		>
			<Grid container>
				<Grid
					xs={6}
					item
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
						bgcolor: "#E7E7E7",
					}}
				>
					<div className="logIn">
						<img src={image2} className="image2" alt="Qistar" />
						<div className="text1">تأكيد رقم الجوال</div>
						<div className="text4">
							قم بإدخال رمز التحقق لتاكيد رقم الهاتف
						</div>
						<OTPInput
							value={otp}
							onChange={setOtp}
							numInputs={5}
							// renderSeparator={<span>-</span>}
							inputStyle={{
								margin: "5px",
								borderRadius: "5px",
								width: "2.5rem",
								height: "2.5rem",
								border: "1px solid rgba(51, 51, 51, 0.60)",
							}}
							// inputStyle="opt-input"
							renderInput={(props) => (
								<input className="otp-input" {...props} />
							)}
						/>
						{showError()}
						<Button>
							<div
								className="text3"
								style={{
									textDecorationLine: "underline",
									fontWeight: "700",
								}}
								onClick={resendOTP}
							>
								إعادة إرسال الرمز
							</div>
						</Button>
					</div>
				</Grid>
				<Grid
					xs={6}
					item
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="bg"
				>
					<img className="image1" src={image1} alt="Qistar" />
				</Grid>
			</Grid>
		</div>
	);
};

export default AdminOTP;
