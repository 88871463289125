import "./App.css";
import "./styles/old.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "./components/SupportDashboard/AdiminLogin";
import AdminOTP from "./components/SupportDashboard/AdminOTP";
import AdminLayout from "./components/SupportDashboard/AdminLayout/AdminLayout";
import AdminDashboard from "./components/SupportDashboard/AdminDashboard/AdminDashboard";
import AdminReq from "./components/SupportDashboard/AdminReq/AdminReq";
import AdminContracts from "./components/SupportDashboard/contracts/AdminContracts";
import AdminSupport from "./components/SupportDashboard/AdminSupport/AdminSupport";
import AdminReqShow from "./components/SupportDashboard/AdminReq/AdminReqShow";
import ContractDetails from "./components/SupportDashboard/contracts/ContractDetails";
import TicketDetails from "./components/SupportDashboard/AdminSupport/TicketDetails";
import Customers from "./components/SupportDashboard/customers/Customers";
import CustomerDetails from "./components/SupportDashboard/customers/CustomerDetails";
import Brokers from "./components/SupportDashboard/brokers/Brokers";
import BrokerDetails from "./components/SupportDashboard/brokers/BrokerDetails";
import Settings from "./components/SupportDashboard/settings/Settings";
import CreditRisk from "./components/SupportDashboard/creditRisk/CreditRisk";
import Users from "./components/SupportDashboard/users/Users";
import UserDetails from "./components/SupportDashboard/users/UserDetails";
import AddUser from "./components/SupportDashboard/users/AddUser";
import Profile from "./components/SupportDashboard/profile/Profile";
import Memorandum from "./components/SupportDashboard/memorandum/Memorandum";
import MemorandumDetails from "./components/SupportDashboard/memorandum/MemorandumDetails";
import PaymentRequests from "./components/SupportDashboard/brokers/payment/PaymentRequests";
import OwnerPaymentRequests from "./components/SupportDashboard/customers/payment/PaymentRequests";

function App() {
  //test
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/admin/otp" element={<AdminOTP />} />
          <Route
            path="/admin/home"
            element={<AdminLayout element={<AdminDashboard />} />}
          />
          <Route
            path="/admin/requests"
            element={<AdminLayout element={<AdminReq />} />}
          />
          <Route
            path="/admin/request/:id"
            element={<AdminLayout element={<AdminReqShow />} />}
          />
          <Route
            path="/admin/contracts"
            element={<AdminLayout element={<AdminContracts />} />}
          />
          <Route
            path="/admin/contracts/:id"
            element={<AdminLayout element={<ContractDetails />} />}
          />
          <Route
            path="/admin/support"
            element={<AdminLayout element={<AdminSupport />} />}
          />
          <Route
            path="/admin/support/:id"
            element={<AdminLayout element={<TicketDetails />} />}
          />
          <Route
            path="/admin/customers"
            element={<AdminLayout element={<Customers />} />}
          />
          <Route
            path="/admin/customers/:id"
            element={<AdminLayout element={<CustomerDetails />} />}
          />
          <Route
            path="/admin/brokers"
            element={<AdminLayout element={<Brokers />} />}
          />
          <Route
            path="/admin/brokers/:id"
            element={<AdminLayout element={<BrokerDetails />} />}
          />
          <Route
            path="/admin/brokers/payment/requests"
            element={<AdminLayout element={<PaymentRequests />} />}
          />
          <Route
            path="/admin/customers/payment/requests"
            element={<AdminLayout element={<OwnerPaymentRequests />} />}
          />
          <Route
            path="/admin/settings"
            element={<AdminLayout element={<Settings />} />}
          />
          <Route
            path="/admin/credit-risks"
            element={<AdminLayout element={<CreditRisk />} />}
          />
          <Route
            path="/admin/users"
            element={<AdminLayout element={<Users />} />}
          />
          <Route
            path="/admin/users/:phone"
            element={<AdminLayout element={<UserDetails />} />}
          />
          <Route
            path="/admin/users/add"
            element={<AdminLayout element={<AddUser />} />}
          />
          <Route
            path="/admin/profile"
            element={<AdminLayout element={<Profile />} />}
          />
          <Route
            path="/admin/memorandum"
            element={<AdminLayout element={<Memorandum />} />}
          />
          <Route
            path="/admin/memorandum/:id"
            element={<AdminLayout element={<MemorandumDetails />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
