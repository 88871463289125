import { useEffect, useState } from "react";
import server from "../../../api/server";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useNavStore } from "../../../store";
import { LoadingButton } from "@mui/lab";

const Settings = () => {
  const token = localStorage.getItem("admin-token");
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const handleChange = (key, value) => {
    setSettings((prev) => ({ ...prev, [key]: value }));
  };
  const getSettings = async () => {
    try {
      const { data } = await server.get("/admin-app/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSettings(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettings = async () => {
    setLoadingUpdate(true);
    try {
      const { data } = await server.post(
        "/admin-app/update-settings",
        { ...settings },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSettings(data.data);
      setLoadingUpdate(false);
      setFeedback(true);
    } catch (error) {
      console.log(error);
      setLoadingUpdate(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback(false);
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الإعدادات",
      },
    });
    getSettings();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Snackbar open={feedback} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم التحديث بنجاح</div>
        </Alert>
      </Snackbar>
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center" style={{ marginRight: "0%" }}>
            الإعدادات
          </div>
        </div>
      </div>
      {!loading ? (
        <Box
          sx={{
            direction: "rtl",
            background: "#fff",
            p: 2,
            borderRadius: "8px",
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={12} md={6}>
              <div className="text23 mr0"> اسم المنصة</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="platformName"
                  value={settings.platformName}
                  onChange={(e) => handleChange("platformName", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">المالك</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="website_company_author"
                  value={settings.website_company_author}
                  onChange={(e) =>
                    handleChange("website_company_author", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">رقم السجل التجاري</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="license_no"
                  value={settings.license_no}
                  onChange={(e) => handleChange("license_no", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">تاريخ الإنتهاء</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="license_expired_date"
                  value={settings.license_expired_date}
                  onChange={(e) =>
                    handleChange("license_expired_date", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">تخفيض المالك (نسبة مئوية)</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="owner_discount"
                  value={settings.owner_discount}
                  onChange={(e) =>
                    handleChange("owner_discount", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="text23 mr0">الضريبة (نسبة مئوية)</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="vat_tax"
                  value={settings.vat_tax}
                  onChange={(e) => handleChange("vat_tax", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="text23 mr0">الوصف</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  multiline
                  minRows={4}
                  maxRows={10}
                  fullWidth
                  name="description"
                  value={settings.description}
                  sx={{
                    ".MuiOutlinedInput-input": {
                      color: "#014E97",
                      fontFamily: "font",
                    },
                  }}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                العنوان الوطني
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="text23 mr0">المدينة</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="cityName"
                  value={settings.cityName}
                  onChange={(e) => handleChange("cityName", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="text23 mr0">الرمز المختصر</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="districtCode"
                  value={settings.districtCode}
                  onChange={(e) => handleChange("districtCode", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="text23 mr0">الحي</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="districtName"
                  value={settings.districtName}
                  onChange={(e) => handleChange("districtName", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="text23 mr0">رقم المبنى</div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                  fontFamily: "font-normal",
                }}
              >
                <TextField
                  fullWidth
                  name="landNumber"
                  value={settings.landNumber}
                  onChange={(e) => handleChange("landNumber", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loadingUpdate}
                variant="contained"
                size="large"
                sx={{
                  marginLeft: "10px",
                  width: "max-content",
                  bgcolor: "#014E97",
                  height: "auto",
                  paddingInline: 10,
                }}
                onClick={updateSettings}
              >
                <div className="text3">تحديث</div>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Settings;
