import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

export default function SearchBar({ setSearch }) {
	return (
		<Paper
			component="form"
			variant="outlined"
			sx={{
				p: "2px 4px",
				display: "flex",
				alignItems: "center",
				width: 400,
				direction: "rtl",
				height: 30,
				boxShadow: "none",
			}}
		>
			<InputBase
				sx={{ ml: 1, flex: 1, fontFamily: "font" }}
				placeholder="بحث"
				inputProps={{ "aria-label": "بحث" }}
				size="small"
				onChange={(e) => setSearch(e.target.value)}
			/>
			<IconButton
				size="small"
				type="button"
				sx={{ p: "10px" }}
				aria-label="search"
				disabled
			>
				<SearchIcon />
			</IconButton>
		</Paper>
	);
}
