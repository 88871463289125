import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import NotificationsList from "./NotificationsList";
import { AccountCircleOutlined } from "@mui/icons-material";
import BreadcrumbsNav from "../../utils/Breadcrumbs";
import { useUserStor } from "../../../store";

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const [notifications, setNotifications] = React.useState([]);
  const [newNotifications, setNewNotifications] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const { adminData } = useUserStor();

  // const user = JSON.parse(localStorage.getItem("admin"));
  const user = adminData;
  const localToken = localStorage.getItem("admin-token");

  const updateData = async () => {
    try {
      const { data } = await server.get(
        "/admin-app/get-notifications?is_read=0",
        {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        }
      );
      setNewNotifications(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignout = () => {
    localStorage.removeItem("admin-token");
    localStorage.removeItem("admin");
    navigate("/");
  };

  React.useEffect(() => {
    updateData();
  }, []);

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <Box sx={{ flexGrow: 1, direction: "rtl", backgroundColor: "#fff" }}>
      <AppBar
        position="static"
        color="transparent"
        variant="outlined"
        elevation={0}
      >
        <Toolbar>
          <BreadcrumbsNav />

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                marginLeft: "20px",
                alignItems: "center",
              },
              transform: "scale(0.8)",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(67, 122, 179, 0.20)",
                borderRadius: "50%",
                display: "flex",
                width: "40px",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                size="medium"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => setOpen(true)}
              >
                {newNotifications.length === 0 ? (
                  <NotificationsIcon />
                ) : (
                  <Badge variant="dot" color="success">
                    <NotificationsIcon />
                  </Badge>
                )}
              </IconButton>
            </div>
            <Grid container direction={"column"} sx={{ marginInline: "20px" }}>
              <Grid>
                <div className="text6">{user?.name}</div>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={handleSignout}>
                  <div className="text7">تسجيل خروج</div>
                </Button>
              </Grid>
            </Grid>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate("/admin/profile")}
            >
              {user.profile_pic ? (
                <img
                  src={`https://api.qistar.sa/storage/${user?.profile_pic}`}
                  className="pro-img"
                  alt="profile"
                />
              ) : (
                <AccountCircleOutlined fontSize="large" />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <NotificationsList data={notifications} open={open} setOpen={setOpen} />
    </Box>
  );
}
