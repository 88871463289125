import { Grid, Button, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import server from "../../../api/server";
import CustomPaginationActionsTable from "./DataGrid";
import { useNavStore } from "../../../store";

const AdminSupport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);

  const token = localStorage.getItem("admin-token");

  const getData = async () => {
    try {
      const { data } = await server.get("/admin-app/get-tickets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const { data } = await server.get(
        `/admin-app/get-tickets?serialNumber=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data.data);
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "الشكاوى والدعم الفني",
      },
    });
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid item xs={12} sx={{ direction: "rtl", width: "100%" }}>
        <div
          className="box10"
          style={{
            display: "flex",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div className="box11" style={{ width: "100%" }}>
            <div className="text8 center">الشكاوى والدعم الفني</div>
          </div>
        </div>
      </Grid>
      <div className="box14" style={{ margin: "2rem", height: "auto" }}>
        <div className="box10 box50">
          <div className="box11 box51" style={{ paddingRight: 0 }}>
            <SearchBar setSearch={setSearch} />
            <Button
              variant="contained"
              size="small"
              onClick={handleSearch}
              sx={{
                marginRight: "10px",
                // width: "10vw",
                bgcolor: "#014E97",
                height: "35px",
              }}
            >
              <div className="text3">بحث</div>
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#F4F7FF",
            padding: 10,
            borderRadius: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          {data ? (
            <CustomPaginationActionsTable rows={data} />
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSupport;
