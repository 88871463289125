import { Grid } from "@mui/material";

const Offers = ({ req }) => {
	const renderStatus = (status) => {
		if (status === 1) {
			return (
				<div
					style={{
						width: "8vmax",
						backgroundColor: "#27AE60",
						paddingBlock: 10,
						textAlign: "center",
						justifyContent: "center",
						borderRadius: "6px",
					}}
					className="flex-center"
				>
					<div
						className="text12"
						style={{
							color: "#fff",
							textAlign: "center",
						}}
					>
						تمت الموافقة
					</div>
				</div>
			);
		} else if (status === 2) {
			return (
				<div
					style={{
						width: "8vmax",
						backgroundColor: "#FF1F00",
						paddingBlock: 10,
						textAlign: "center",
						justifyContent: "center",
						borderRadius: "6px",
					}}
					className="flex-center"
				>
					<div
						className="text12"
						style={{
							color: "#fff",
							textAlign: "center",
						}}
					>
						تم الرفض
					</div>
				</div>
			);
		} else if (status === 3) {
			return (
				<div
					style={{
						width: "8vmax",
						backgroundColor: "#FFC300",
						paddingBlock: 10,
						textAlign: "center",
						justifyContent: "center",
						borderRadius: "6px",
					}}
					className="flex-center"
				>
					<div
						className="text12"
						style={{
							color: "#fff",
							textAlign: "center",
						}}
					>
						مناصفة
					</div>
				</div>
			);
		} else {
			return (
				<div
					style={{
						width: "8vmax",
						backgroundColor: "#d4d800",
						paddingBlock: 10,
						textAlign: "center",
						justifyContent: "center",
						borderRadius: "6px",
					}}
					className="flex-center"
				>
					<div
						className="text12"
						style={{
							color: "#fff",
							textAlign: "center",
						}}
					>
						لم يتم الرد
					</div>
				</div>
			);
		}
	};

	const renderOffers = () => {
		if (req.offers.length === 0 || !req.offers) {
			return (
				<div
					className="text8"
					style={{
						width: "100%",
						padding: "5px",
						borderTop: "10px",
						marginRight: "0%",
						textAlign: "right",
						justifyContent: "flex-end",
					}}
				>
					لايوجد عرض
				</div>
			);
		}
		return req.offers.map((offer) => {
			if (offer.customer_id === req.renter_id) {
				return (
					<div className="box-3" key={Math.random()}>
						<div
							className="text8"
							style={{
								width: "100%",
								padding: "5px",
								borderTop: "10px",
								marginRight: "0%",
							}}
						>
							معلومات التعاقد
						</div>
						<div className="box2 m0" style={{ width: "auto" }}>
							<Grid item xs={4}>
								<p className="text11">عرض المستأجر</p>
								<p className="text12">
									{offer.rent_amount} ريال سنويا
								</p>
								<p className="text25">
									يتم تقسيط المبلغ على 12 شهر بمعدل
									{Math.round(
										Number(offer.total_amount) / 12
									)}{" "}
									ريال شهريا.
								</p>
							</Grid>
							<Grid item xs={4}>
								<p className="text11">الرسوم الإدارية</p>
								<p className="text12">{offer.fees} ريال</p>
							</Grid>
							<Grid item xs={4}>
								<p className="text11">الحالة</p>
								{renderStatus(offer.status)}
							</Grid>
						</div>
					</div>
				);
			} else {
				return (
					<div className="box-3" key={Math.random()}>
						<div
							className="text8"
							style={{
								width: "100%",
								padding: "5px",
								borderTop: "10px",
								marginRight: "0%",
							}}
						>
							معلومات التعاقد
						</div>
						<div className="box2 m0" style={{ width: "auto" }}>
							<Grid item xs={4}>
								<p className="text11">عرض المالك</p>
								<p className="text12">
									{offer.rent_amount} ريال سنويا
								</p>
							</Grid>
							<Grid item xs={4}>
								<p className="text11">رسوم التخفيض</p>
								<p className="text12">{offer.fees} ريال</p>
							</Grid>
							<Grid item xs={4}>
								<p className="text11">الحالة</p>
								{renderStatus(offer.status)}
							</Grid>
						</div>
					</div>
				);
			}
		});
	};

	return <>{renderOffers()}</>;
};

export default Offers;
