import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Button, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

// TablePaginationActions.propTypes = {
// 	count: PropTypes.number.isRequired,
// 	onPageChange: PropTypes.func.isRequired,
// 	page: PropTypes.number.isRequired,
// 	rowsPerPage: PropTypes.number.isRequired,
// };

function createData(name, calories, fat) {
  return { name, calories, fat };
}

export default function CustomPaginationActionsTable({
  rows,
  noPagination,
  hide,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const navigate = useNavigate();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (row) => {
    // localStorage.setItem("req", JSON.stringify(row));
    navigate(`/admin/brokers/${row.phone}`);
  };

  const modelType = (type) => {
    if (type === 1) {
      return "مستأجر";
    } else if (type === 2) {
      return "مالك";
    } else {
      return "وسيط";
    }
  };

  const renderStatus = (is_active) => {
    if (is_active === 0) {
      return (
        <div
          className="text12 mr0 f-normal"
          style={{ color: "#FF1F00", marginTop: 10 }}
        >
          غير نشط
        </div>
      );
    } else if (is_active === 1) {
      return (
        <div
          className="text12 mr0 f-normal"
          style={{ color: "green", marginTop: 10 }}
        >
          نشط
        </div>
      );
    }
  };

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  return (
    <TableContainer
      component={Paper}
      sx={{ direction: "rtl", border: "none", boxShadow: "none" }}
    >
      <Table
        sx={{
          minWidth: 500,
          border: "none",
          boxShadow: "none",
          // width: "100%",
        }}
        aria-label="custom pagination table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="text6" style={{ color: "#080D2B" }}>
                رقم العميل
              </div>
            </TableCell>
            <TableCell>
              <div className="text6" style={{ color: "#080D2B" }}>
                الاسم
              </div>
            </TableCell>
            <TableCell>
              <div className="text6" style={{ color: "#080D2B" }}>
                نوع العميل
              </div>
            </TableCell>
            <TableCell>
              <div className="text6" style={{ color: "#080D2B" }}>
                رقم الجوال
              </div>
            </TableCell>
            <TableCell>
              <div className="text6" style={{ color: "#080D2B" }}>
                حالة الحساب
              </div>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <div
                className="text6"
                style={{ color: "#080D2B", textAlign: "center" }}
              >
                خيارات
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {(rowsPerPage > 0 || hide */}
          {(hide
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.id} sx={{ backgroundColor: "#F4F7FF" }}>
              <TableCell component="th" scope="row">
                <div className="text9 f-normal" style={{ color: "#080D2B" }}>
                  {`${row.id}#`}
                </div>
              </TableCell>
              <TableCell align="right">
                <div className="text9 f-normal" style={{ color: "#080D2B" }}>
                  {row.name}
                </div>
              </TableCell>
              <TableCell align="right">
                <div className="text9 f-normal" style={{ color: "#080D2B" }}>
                  {modelType(row.customer_type_id)}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="text9 f-normal" style={{ color: "#080D2B" }}>
                  {row.phone}
                </div>
              </TableCell>
              <TableCell align="center">
                {renderStatus(row.is_active)}
              </TableCell>
              <TableCell align="right">
                <div className="box52">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      marginLeft: "10px",
                      width: "10vw",
                      bgcolor: "#014E97",
                    }}
                    onClick={() => handleCheck(row)}
                  >
                    <div className="text3">عرض</div>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {/* <TableFooter> */}
        {/* <TableRow> */}
        {/* <TablePagination
							// rowsPerPageOptions={[
							// 	5,
							// 	10,
							// 	25,
							// 	{ label: "All", value: -1 },
							// ]}
							colSpan={3}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/> */}
        {/*  */}
        {/* </TableRow> */}
        {/* </TableFooter> */}
      </Table>
      {!noPagination && (
        <Pagination
          count={Math.ceil(rows.length / 15)}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "end",
            direction: "ltr",
            width: "max-content",
            marginBlock: 1,
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: "#014E97",
                color: "white",
                // borderRadius: '50%',
              },
            },
          }}
        />
      )}
    </TableContainer>
  );
}
